<template>
  <picture
    class="res-picture"
    :class="{single: webp.length === 1}"
  >
    <template v-if="webp.length === 1">
      <source
        :srcset="webp[0]"
        type="image/webp"
      />
    </template>
    <template v-else>
      <source
        v-for="(src, mediaQuery) in webp"
        :key="mediaQuery"
        :srcset="src"
        :media="`(${mediaQuery})`"
        type="image/webp"
      />
    </template>
    <img
      :src="jpg"
      :alt="alt"
      :style="css"
      :class="{'-opaque': opaque}"
      decoding="async"
      loading="lazy"
      @load="onImgLoad"
    />
  </picture>
</template>
<script setup>
import { ref } from 'vue'
defineProps({
  webp: {
    type: Object,
    default: () => ({}),
  },
  jpg: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    default: '',
  },
  css: {
    type: String,
    default: '',
  },
})

const opaque = ref(false)

const onImgLoad = () => {
  opaque.value = true
}

</script>
