<template>
  <div
    class="-mobile-cta-and-search -fade-early"
    :class="{
      '-search-active': searchMobileActive,
      '-search-result-loading': searchResultLoading,
    }"
  >
    <div class="-head">
      <div class="-left">
        <a
          class="button -sml cursor-pointer"
          @click.prevent="openModal(1)"
        >Ready to talk?</a>
        <div class="-search-term">
          <input
            v-model="searchTerm"
            type="text"
            placeholder="Search"
            @keydown="mobileSearchKeydown"
          >
        </div>
      </div>
      <div
        class="-magnify-glass"
      >
        <MagnifyGlass
          class="text-12"
          @click="toggleSearchMobile"
        />
      </div>
    </div>
    <div
      v-if="searchResultLoading"
      class="loader-dots"
    >
      <LoaderDots />
    </div>
    <div
      class="-search-results"
    >
      <div
        ref="mobileSearchResultsHeight"
      >
        <a
          v-for="(result, i) in searchResults"
          :key="i"
          :href="result.href"
        >
          <span class="-chev">
            <ChevRight />
          </span>
          <span class="-title">{{ result.title }}</span>
        </a>
        <div v-if="searchApiResponded && searchResults.length === 0">
          <p>Sorry, no results</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import MagnifyGlass from '~/svg/MagnifyGlass.vue'
import ChevRight from '~/svg/ChevRight.vue'
import useTopNavDrop from '#/useTopNavDrop.js'
import useModalEnquiry from '#/useModalEnquiry.js'
import LoaderDots from '~/svg/LoaderDots.vue'

const {
  searchMobileActive,
  toggleSearchMobile,
  mobileSearchResultsHeight,
  searchTerm,
  searchResultLoading,
  searchResults,
  searchApiResponded,
  mobileSearchKeydown,
} = useTopNavDrop()

const { tab } = useModalEnquiry()
const openModal = (i) => {
  tab.value = i
}




</script>
