<template>
  <div
    class="cta-fixed"
    :class="{
      '-is-minimised': isMinimised,
      '-animate-show': animateShow === true,
      '-animate-hide': animateShow === false,
    }"
  >
    <div class="-maximal">
      <div class="-sliding">
        <div class="-top">
          <h6 class="my-0">
            Ready to talk?
          </h6>
          <span
            class="py-2 inline-block cursor-pointer text-gold hover:text-black transition-colors"
            @click="setMinimise(true)"
          >
            <IconX class="-x" />
          </span>
        </div>
        <div
          class="-show-contact hover:text-white"
          @click="openModal(1)"
        >
          <h6 class="my-0 sml-caps">
            Make an enquiry
          </h6>
          <ChevRight />
        </div>
        <div
          class="-show-contact hover:text-white"
          @click="openModal(2)"
        >
          <h6 class="my-0 sml-caps">
            Give us a call
          </h6>
          <ChevRight />
        </div>
        <div
          class="-show-contact hover:text-white"
          @click="openModal(3)"
        >
          <h6 class="my-0 sml-caps">
            Book appointment
          </h6>
          <ChevRight />
        </div>
        <div
          class="-show-contact hover:text-white"
          @click="openModal(4)"
        >
          <h6 class="my-0 sml-caps">
            Begin Online
          </h6>
          <ChevRight />
        </div>
      </div>
    </div>
    <div
      v-if="! initialFullHide"
      class="-minimal"
    >
      <div class="-sliding">
        <div class="-tab">
          <span
            @click="setMinimise(false)"
          >
            <IconTalk />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import IconX from '../svg/IconX.vue'
import IconTalk from '../svg/IconTalk.vue'
import ChevRight from '../svg/ChevRight.vue'
import useModalEnquiry from '../../composables/useModalEnquiry.js'

const { tab } = useModalEnquiry()
const initialFullHide = ref(true)
const isMinimised = ref(true)
const animateShow = ref(null)
const inTransition = ref(false)



const openModal = (i) => {
  tab.value = i
}

const ctaIsMinLocStorage = localStorage.getItem('ctaIsMin');

const setMinimise = (val) => {
  if (inTransition.value) {
    return false
  }
  inTransition.value = true
  isMinimised.value = val
  animateShow.value = val
  localStorage.setItem('ctaIsMin', val ? '1' : '0')
  setTimeout(() => {
    inTransition.value = false
    animateShow.value = null
  }, 500)
}

onMounted(() => {
  setTimeout(() => {
    setMinimise(ctaIsMinLocStorage === '1')
    setTimeout(() => {
      initialFullHide.value = false
    }, 1500)
  }, 500)
})


</script>
