<template>
  <div
    ref="slideEl"
    class="-slide"
    :class="{ '-abstract-is-showing': abstractIsShowing }"
  >
    <div class="-uniform-width">
      <header>
        <div
          v-if="typeof slide.img !== 'undefined' && slide.img"
          class="-img picture-inset"
        >
          <ResPicture v-bind="slide.img" />
        </div>
        <ResizingCaption
          :state="resizingCaptionState"
          :title="slide.title"
          :abstract="slide.abstract"
          :cta="slide.cta"
        />
      </header>
      <footer>
        <div class="-links">
          <a
            v-for="(link, j) in slide.links"
            :key="j"
            :href="link.href"
          >{{ link.text }}</a>
        </div>
      </footer>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted } from 'vue'
import ResPicture from './widgets/ResPicture.vue'
import ResizingCaption from './widgets/ResizingCaption.vue'
import ResizingCaptionState from '../classes/ResizingCaptionState.js'

const props = defineProps({
  slide: {
    type: Object,
    required: true,
  },
  state: {
    type: Object,
    required: true,
  },
  i: {
    type: Number,
    required: true,
  },
})


const slideEl = ref(null)

const resizingCaptionState = new ResizingCaptionState()
const abstractIsShowing = resizingCaptionState.abstractIsShowing

const checkIfShouldShow = (i) => {
  if (i === props.i) {
    if (abstractIsShowing.value !== true) {
      if (abstractIsShowing.value === false) {
        props.state.moveTo(slideEl.value)
      }
      resizingCaptionState.maximise()
    }
  } else if (abstractIsShowing.value !== false) {
    resizingCaptionState.minimise()
  }
}

watch(props.state.currentI, (newI) => {
  checkIfShouldShow(newI)
})


onMounted(() => {
  props.state.registerSlideEl(props.i, slideEl)
})
resizingCaptionState.mountedCallback = () => {
  checkIfShouldShow(props.state.currentI.value)
}

</script>
