<template>
  <svg
    id="account-group"
    xmlns="http://www.w3.org/2000/svg"
    width="3.9725em"
    height="2.4em"
    viewBox="0 0 39.725 24"
  >
    <path
      id="account-group-2"
      data-name="account-group"
      d="M19.862,5.5a5.793,5.793,0,1,1-5.793,5.793A5.793,5.793,0,0,1,19.862,5.5M8.276,9.638a4.886,4.886,0,0,1,2.532.7,9.163,9.163,0,0,0,1.87,6.554,4.963,4.963,0,1,1-4.4-7.25m23.173,0a4.966,4.966,0,1,1-4.4,7.25,9.163,9.163,0,0,0,1.87-6.554,4.886,4.886,0,0,1,2.532-.7M9.1,26.6c0-3.426,4.817-6.207,10.759-6.207S30.621,23.177,30.621,26.6v2.9H9.1V26.6M0,29.5V27.017c0-2.3,3.128-4.237,7.366-4.8A6.694,6.694,0,0,0,5.793,26.6v2.9H0m39.725,0H33.931V26.6a6.694,6.694,0,0,0-1.572-4.386c4.237.563,7.366,2.5,7.366,4.8Z"
      transform="translate(0 -5.5)"
      fill="currentColor"
    />
  </svg>
</template>
