<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2.64em"
    height="2.4em"
    viewBox="0 0 26.4 24"
  >
    <path
      d="M20.6,19.384l2.928,1.692-.9,1.56L18.8,20.428V16h1.8v3.384m7.8.216a8.4,8.4,0,0,1-16.8,0,7.961,7.961,0,0,1,.1-1.2H2V4H23.6v8.016A8.412,8.412,0,0,1,28.4,19.6M12.416,16a9.761,9.761,0,0,1,.72-1.236,1.033,1.033,0,0,1-.336.036,3.6,3.6,0,1,1,3.6-3.6,3.507,3.507,0,0,1-.12.876A8.444,8.444,0,0,1,20,11.2a7.961,7.961,0,0,1,1.2.1V8.8a2.4,2.4,0,0,1-2.4-2.4H6.8A2.392,2.392,0,0,1,4.4,8.8v4.8A2.4,2.4,0,0,1,6.8,16h5.616M26,19.6a6,6,0,1,0-6,6A6,6,0,0,0,26,19.6Z"
      transform="translate(-2 -4)"
      fill="currentColor"
    />
  </svg>
</template>
