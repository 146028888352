<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="271.955" height="36.92" viewBox="0 0 271.955 36.92">
    <title>Robbins Watson Logo</title>
    <g id="Group_1394" data-name="Group 1394" transform="translate(21566 1594.198)">
      <g id="Group_1391" data-name="Group 1391" transform="translate(-21471.354 -1594.198)">
        <g id="Group_1389" data-name="Group 1389" transform="translate(0.645 29.909)">
          <g id="Group_1362" data-name="Group 1362" transform="translate(0 0)">
            <path id="Path_1081" data-name="Path 1081" d="M-5.783-1.643a3.177,3.177,0,0,0-.9-.144,1.654,1.654,0,0,0-.9.217.679.679,0,0,0-.337.6.781.781,0,0,0,.2.56,1.448,1.448,0,0,0,.5.344c.2.083.473.181.824.292A9.311,9.311,0,0,1-5.326.613a1.839,1.839,0,0,1,.7.552,1.528,1.528,0,0,1,.286.968,1.616,1.616,0,0,1-.3.972,1.934,1.934,0,0,1-.843.645,3.2,3.2,0,0,1-1.245.226A3.623,3.623,0,0,1-8.123,3.7,3.765,3.765,0,0,1-9.29,2.956l.4-.769a3.325,3.325,0,0,0,1.042.7,2.866,2.866,0,0,0,1.124.246A1.8,1.8,0,0,0-5.7,2.878a.8.8,0,0,0,.374-.691.8.8,0,0,0-.2-.565,1.456,1.456,0,0,0-.514-.352A8.758,8.758,0,0,0-6.882.983,8.57,8.57,0,0,1-7.937.6a1.825,1.825,0,0,1-.69-.547A1.505,1.505,0,0,1-8.91-.9a1.525,1.525,0,0,1,.288-.918,1.823,1.823,0,0,1,.8-.612,3.165,3.165,0,0,1,1.205-.213,3.92,3.92,0,0,1,1.145.171,3.888,3.888,0,0,1,1,.458l-.372.8a3.661,3.661,0,0,0-.939-.43" transform="translate(9.29 2.639)" fill="currentColor"/>
          </g>
          <g id="Group_1363" data-name="Group 1363" transform="translate(10.886 0.018)">
            <path id="Path_1082" data-name="Path 1082" d="M-8.454-.718A3.29,3.29,0,0,1-7.216.473a3.165,3.165,0,0,1,.455,1.663,3.215,3.215,0,0,1-.455,1.673,3.3,3.3,0,0,1-1.238,1.2,3.447,3.447,0,0,1-1.718.44,3.454,3.454,0,0,1-1.719-.44,3.266,3.266,0,0,1-1.235-1.2,3.208,3.208,0,0,1-.455-1.673A3.158,3.158,0,0,1-13.126.473,3.253,3.253,0,0,1-11.894-.718a3.463,3.463,0,0,1,1.721-.435,3.479,3.479,0,0,1,1.718.435m-2.963.7a2.528,2.528,0,0,0-.921.9,2.379,2.379,0,0,0-.345,1.256,2.4,2.4,0,0,0,.345,1.26,2.55,2.55,0,0,0,.921.912,2.435,2.435,0,0,0,1.255.338,2.4,2.4,0,0,0,1.247-.338A2.55,2.55,0,0,0-8.009,3.4a2.441,2.441,0,0,0,.337-1.26A2.419,2.419,0,0,0-8.009.88a2.528,2.528,0,0,0-.907-.9,2.428,2.428,0,0,0-1.247-.334,2.463,2.463,0,0,0-1.255.334" transform="translate(13.581 1.153)" fill="currentColor"/>
          </g>
          <g id="Group_1365" data-name="Group 1365" transform="translate(24.077 0.075)">
            <path id="Path_1084" data-name="Path 1084" d="M0,0H.891V5.678H3.724v.806H0Z" fill="currentColor"/>
          </g>
          <path id="Path_1085" data-name="Path 1085" d="M342.563-18.375h.889v6.484h-.889Z" transform="translate(-308.536 18.449)" fill="currentColor"/>
          <g id="Group_1366" data-name="Group 1366" transform="translate(41.289 0.018)">
            <path id="Path_1086" data-name="Path 1086" d="M-7.27-1.657a2.47,2.47,0,0,0-1.01-.217,2.466,2.466,0,0,0-1.259.334,2.481,2.481,0,0,0-.907.9A2.441,2.441,0,0,0-10.781.618a2.451,2.451,0,0,0,.335,1.251,2.48,2.48,0,0,0,.907.907,2.47,2.47,0,0,0,1.259.334,2.56,2.56,0,0,0,1-.208,2.9,2.9,0,0,0,.87-.57l.538.583a3.636,3.636,0,0,1-1.139.755,3.365,3.365,0,0,1-1.316.273,3.357,3.357,0,0,1-1.7-.44,3.238,3.238,0,0,1-1.213-1.2A3.257,3.257,0,0,1-11.678.626a3.172,3.172,0,0,1,.45-1.663A3.243,3.243,0,0,1-10-2.227a3.455,3.455,0,0,1,1.714-.435,3.483,3.483,0,0,1,1.311.256,3.25,3.25,0,0,1,1.1.718l-.529.639a2.683,2.683,0,0,0-.863-.607" transform="translate(11.678 2.662)" fill="currentColor"/>
          </g>
          <path id="Path_1088" data-name="Path 1088" d="M393.977-18.375h.89v6.484h-.89Z" transform="translate(-340.542 18.449)" fill="currentColor"/>
          <g id="Group_1368" data-name="Group 1368" transform="translate(60.438 0.075)">
            <path id="Path_1089" data-name="Path 1089" d="M0,0H5.066V.815H2.973V6.484H2.083V.815H0Z" fill="currentColor"/>
          </g>
          <g id="Group_1369" data-name="Group 1369" transform="translate(70.636 0.018)">
            <path id="Path_1090" data-name="Path 1090" d="M-8.456-.718A3.286,3.286,0,0,1-7.219.473a3.165,3.165,0,0,1,.455,1.663,3.216,3.216,0,0,1-.455,1.673,3.292,3.292,0,0,1-1.237,1.2,3.447,3.447,0,0,1-1.719.44,3.451,3.451,0,0,1-1.719-.44,3.263,3.263,0,0,1-1.235-1.2,3.216,3.216,0,0,1-.456-1.673A3.165,3.165,0,0,1-13.128.473,3.255,3.255,0,0,1-11.9-.718a3.468,3.468,0,0,1,1.721-.435,3.478,3.478,0,0,1,1.719.435m-2.963.7a2.534,2.534,0,0,0-.922.9,2.38,2.38,0,0,0-.345,1.256,2.4,2.4,0,0,0,.345,1.26,2.556,2.556,0,0,0,.922.912,2.433,2.433,0,0,0,1.254.338,2.4,2.4,0,0,0,1.247-.338A2.539,2.539,0,0,0-8.012,3.4a2.434,2.434,0,0,0,.338-1.26A2.413,2.413,0,0,0-8.012.88a2.517,2.517,0,0,0-.906-.9,2.428,2.428,0,0,0-1.247-.334,2.462,2.462,0,0,0-1.254.334" transform="translate(13.584 1.153)" fill="currentColor"/>
          </g>
          <g id="Group_1370" data-name="Group 1370" transform="translate(83.83 0.075)">
            <path id="Path_1091" data-name="Path 1091" d="M-6.9-10.692-8.188-12.73c-.149.012-.269.018-.362.018H-10.2v2.02h-.889v-6.484H-8.55a2.863,2.863,0,0,1,1.9.569,2,2,0,0,1,.67,1.616,2.321,2.321,0,0,1-.364,1.325,2.048,2.048,0,0,1-1.052.778l1.509,2.2ZM-8.55-13.517a1.971,1.971,0,0,0,1.279-.365,1.32,1.32,0,0,0,.445-1.08,1.268,1.268,0,0,0-.445-1.051,2,2,0,0,0-1.279-.357H-10.2v2.854Z" transform="translate(11.088 17.176)" fill="currentColor"/>
          </g>
          <g id="Group_1371" data-name="Group 1371" transform="translate(94.964 0)">
            <path id="Path_1092" data-name="Path 1092" d="M-5.782-1.643a3.166,3.166,0,0,0-.894-.144,1.654,1.654,0,0,0-.9.217.678.678,0,0,0-.337.6.778.778,0,0,0,.2.56,1.436,1.436,0,0,0,.5.344c.2.083.473.181.824.292A9.293,9.293,0,0,1-5.324.613a1.844,1.844,0,0,1,.7.552,1.528,1.528,0,0,1,.286.968,1.616,1.616,0,0,1-.3.972,1.937,1.937,0,0,1-.844.645,3.2,3.2,0,0,1-1.244.226A3.625,3.625,0,0,1-8.121,3.7a3.767,3.767,0,0,1-1.167-.741l.4-.769a3.322,3.322,0,0,0,1.041.7,2.873,2.873,0,0,0,1.125.246A1.8,1.8,0,0,0-5.7,2.878a.8.8,0,0,0,.375-.691.8.8,0,0,0-.2-.565A1.447,1.447,0,0,0-6.04,1.27,8.844,8.844,0,0,0-6.879.983,8.549,8.549,0,0,1-7.935.6,1.826,1.826,0,0,1-8.625.056,1.5,1.5,0,0,1-8.908-.9a1.525,1.525,0,0,1,.288-.918,1.824,1.824,0,0,1,.8-.612A3.172,3.172,0,0,1-6.61-2.639a3.916,3.916,0,0,1,1.144.171,3.882,3.882,0,0,1,1,.458l-.372.8a3.638,3.638,0,0,0-.939-.43" transform="translate(9.288 2.639)" fill="currentColor"/>
          </g>
        </g>
        <g id="Group_1390" data-name="Group 1390" transform="translate(0 0)">
          <g id="Group_1372" data-name="Group 1372" transform="translate(0 1.18)">
            <path id="Path_1093" data-name="Path 1093" d="M0,0H8.663a8.513,8.513,0,0,1,4.584,1.083,3.719,3.719,0,0,1,1.721,3.393,3.589,3.589,0,0,1-1.613,3.165A7.083,7.083,0,0,1,9.433,8.784a5.428,5.428,0,0,1,2.9.89,4.507,4.507,0,0,1,1.553,2.239l.626,1.924a5.466,5.466,0,0,0,.973,1.878A4.768,4.768,0,0,0,17.111,16.8v.048H12.032a7.132,7.132,0,0,1-1.276-2.6l-.8-2.647A4.742,4.742,0,0,0,8.893,9.662,2.664,2.664,0,0,0,6.929,9H4.956v4.861a4.805,4.805,0,0,0,.276,1.854A2.452,2.452,0,0,0,6.3,16.8v.048H0V16.8a2.456,2.456,0,0,0,1.071-1.083,4.805,4.805,0,0,0,.276-1.854V2.984a4.8,4.8,0,0,0-.276-1.854A2.455,2.455,0,0,0,0,.048ZM4.956.626V8.35H7.34a3.857,3.857,0,0,0,2.79-.95,3.787,3.787,0,0,0,.988-2.852,4.037,4.037,0,0,0-.976-2.9A3.689,3.689,0,0,0,7.34.626Z" fill="currentColor"/>
          </g>
          <g id="Group_1373" data-name="Group 1373" transform="translate(17.133 5.944)">
            <path id="Path_1094" data-name="Path 1094" d="M-10.439-20.319a6.292,6.292,0,0,1-4.513-1.732,5.855,5.855,0,0,1-1.817-4.428,5.853,5.853,0,0,1,1.817-4.428,6.288,6.288,0,0,1,4.513-1.734A6.342,6.342,0,0,1-5.9-30.92a5.845,5.845,0,0,1,1.817,4.44A5.841,5.841,0,0,1-5.9-22.04a6.337,6.337,0,0,1-4.535,1.721m-1.949-1.938a2.093,2.093,0,0,0,1.949,1.505,2.12,2.12,0,0,0,1.961-1.505,10.8,10.8,0,0,0,.663-4.223,10.715,10.715,0,0,0-.663-4.211,2.118,2.118,0,0,0-1.961-1.493,2.091,2.091,0,0,0-1.949,1.493,10.829,10.829,0,0,0-.65,4.211,10.915,10.915,0,0,0,.65,4.223" transform="translate(16.769 32.641)" fill="currentColor"/>
          </g>
          <g id="Group_1374" data-name="Group 1374" transform="translate(30.442)">
            <path id="Path_1095" data-name="Path 1095" d="M-14.007-30.12a4.934,4.934,0,0,1-2.755-.746,3.944,3.944,0,0,1-1.6-2.022,5.136,5.136,0,0,1-1.169,1.757,3.688,3.688,0,0,1-1.792.986,35.1,35.1,0,0,0,.264-5.318V-45.738a3.5,3.5,0,0,0-.3-1.661,2.54,2.54,0,0,0-1.142-.938v-.049h4.91v9.29A4.653,4.653,0,0,1-16.1-41.491a3.966,3.966,0,0,1,2.67-.951A4.74,4.74,0,0,1-9.735-40.78,6.328,6.328,0,0,1-8.254-36.4a6.309,6.309,0,0,1-1.65,4.56,5.446,5.446,0,0,1-4.1,1.721m2.071-6.137a6.7,6.7,0,0,0-.758-3.477,2.355,2.355,0,0,0-2.108-1.24,2.482,2.482,0,0,0-1.949,1.023,4,4,0,0,0-.841,2.635v2.864a3.782,3.782,0,0,0,.816,2.515,2.453,2.453,0,0,0,1.927.974,2.353,2.353,0,0,0,2.166-1.372,8.368,8.368,0,0,0,.746-3.923" transform="translate(22.501 48.386)" fill="currentColor"/>
          </g>
          <g id="Group_1375" data-name="Group 1375" transform="translate(45.314)">
            <path id="Path_1096" data-name="Path 1096" d="M-14.007-30.12a4.937,4.937,0,0,1-2.756-.746,3.943,3.943,0,0,1-1.6-2.022,5.157,5.157,0,0,1-1.169,1.757,3.7,3.7,0,0,1-1.792.986,35.044,35.044,0,0,0,.264-5.318V-45.738a3.5,3.5,0,0,0-.3-1.661,2.54,2.54,0,0,0-1.142-.938v-.049h4.91v9.29A4.658,4.658,0,0,1-16.1-41.491a3.965,3.965,0,0,1,2.67-.951A4.741,4.741,0,0,1-9.735-40.78,6.328,6.328,0,0,1-8.254-36.4a6.309,6.309,0,0,1-1.65,4.56,5.446,5.446,0,0,1-4.1,1.721m2.071-6.137a6.691,6.691,0,0,0-.758-3.477,2.355,2.355,0,0,0-2.108-1.24,2.48,2.48,0,0,0-1.948,1.023,4,4,0,0,0-.841,2.635v2.864a3.78,3.78,0,0,0,.817,2.515,2.452,2.452,0,0,0,1.927.974,2.353,2.353,0,0,0,2.166-1.372,8.363,8.363,0,0,0,.746-3.923" transform="translate(22.501 48.386)" fill="currentColor"/>
          </g>
          <g id="Group_1376" data-name="Group 1376" transform="translate(60.499 0.313)">
            <path id="Path_1097" data-name="Path 1097" d="M-7.935-9.682V-.466a4.426,4.426,0,0,0,.218,1.672,2.245,2.245,0,0,0,1.009.9v.048h-5.9V2.11a2.174,2.174,0,0,0,1-.891A4.67,4.67,0,0,0-11.4-.466V-7.011a3.96,3.96,0,0,0-.252-1.672,2.553,2.553,0,0,0-1.1-.95v-.049Zm-3.284-2.455a1.9,1.9,0,0,1-.589-1.419,1.907,1.907,0,0,1,.589-1.419,2.027,2.027,0,0,1,1.479-.578,2.025,2.025,0,0,1,1.479.578,1.911,1.911,0,0,1,.592,1.419,1.908,1.908,0,0,1-.592,1.419,2.026,2.026,0,0,1-1.479.578,2.027,2.027,0,0,1-1.479-.578" transform="translate(12.747 15.554)" fill="currentColor"/>
          </g>
          <g id="Group_1377" data-name="Group 1377" transform="translate(67.357 5.944)">
            <path id="Path_1098" data-name="Path 1098" d="M0-.4H4.811V2.828A5.089,5.089,0,0,1,6.389.276,3.98,3.98,0,0,1,9.025-.638,3.5,3.5,0,0,1,11.815.495,4.486,4.486,0,0,1,12.8,3.549V8.82a4.378,4.378,0,0,0,.218,1.672,2.23,2.23,0,0,0,1.01.9v.048h-5.9V11.4a2.19,2.19,0,0,0,1-.891A4.653,4.653,0,0,0,9.337,8.82V3.549a3.013,3.013,0,0,0-.482-1.768A1.7,1.7,0,0,0,7.365,1.1,2.173,2.173,0,0,0,5.51,2.07a4.622,4.622,0,0,0-.7,2.731V8.82a4.417,4.417,0,0,0,.218,1.672,2.24,2.24,0,0,0,1.01.9v.048H.144V11.4a2.178,2.178,0,0,0,1-.891A4.661,4.661,0,0,0,1.347,8.82V2.274A3.972,3.972,0,0,0,1.1.6,2.551,2.551,0,0,0,0-.348Z" transform="translate(0 0.638)" fill="currentColor"/>
          </g>
          <g id="Group_1378" data-name="Group 1378" transform="translate(82.519 5.944)">
            <path id="Path_1099" data-name="Path 1099" d="M-7.1-.754a2.067,2.067,0,0,0-1.443.482A1.582,1.582,0,0,0-9.073.955a1.454,1.454,0,0,0,.589,1.263,8.65,8.65,0,0,0,2.347.927,8.435,8.435,0,0,1,3.382,1.6,3.3,3.3,0,0,1,1,2.539,3.346,3.346,0,0,1-1.322,2.8,5.308,5.308,0,0,1-3.345,1.023,9.06,9.06,0,0,1-2.094-.277,8.88,8.88,0,0,0-1.47-.277,1.753,1.753,0,0,0-1.154.554l-.264-4.452h.046q1.925,4.02,4.934,4.02a2.456,2.456,0,0,0,1.623-.53A1.8,1.8,0,0,0-4.164,8.68a1.452,1.452,0,0,0-.7-1.324,9.749,9.749,0,0,0-2.385-.89,7.209,7.209,0,0,1-3.09-1.577,3.422,3.422,0,0,1-.951-2.538,3.162,3.162,0,0,1,1.2-2.587A4.585,4.585,0,0,1-7.1-1.211a6.607,6.607,0,0,1,1.841.3A6.13,6.13,0,0,0-3.9-.61a2.132,2.132,0,0,0,1.3-.6V2.712h-.049A7.236,7.236,0,0,0-4.609.2,3.947,3.947,0,0,0-7.1-.754" transform="translate(11.406 1.211)" fill="currentColor"/>
          </g>
          <g id="Group_1379" data-name="Group 1379" transform="translate(94.67 1.18)">
            <path id="Path_1100" data-name="Path 1100" d="M-26.349-28.175l-4.139-11.07-4.259,11.07h-.12l-5.7-14.39A9.845,9.845,0,0,0-41.4-44.25a3.974,3.974,0,0,0-.927-.964v-.048H-36.1v.048a1.554,1.554,0,0,0-.988,1.348,3.726,3.726,0,0,0,.313,1.372l3.443,8.928,2.477-6.642-.89-2.358a9.678,9.678,0,0,0-.829-1.684,3.951,3.951,0,0,0-.927-.964v-.048h6.137v.048a1.584,1.584,0,0,0-.988,1.348,4.367,4.367,0,0,0,.313,1.372l3.128,9.023,3.224-8.687a3.386,3.386,0,0,0,.194-1.083,2.136,2.136,0,0,0-1.252-1.973v-.048h3.971v.048a5.888,5.888,0,0,0-2.044,2.888l-5.44,14.151Z" transform="translate(42.328 45.261)" fill="currentColor"/>
          </g>
          <g id="Group_1380" data-name="Group 1380" transform="translate(116.304 5.944)">
            <path id="Path_1101" data-name="Path 1101" d="M-21.228-18.414v.048a3.24,3.24,0,0,1-2.624,1.083q-2.454,0-2.765-2.142c0-.032-.01-.16-.025-.385s-.025-.368-.025-.433a3.474,3.474,0,0,1-1.347,2.082,4.121,4.121,0,0,1-2.575.83A4,4,0,0,1-33.1-18.1a2.657,2.657,0,0,1-1-2.239,2.776,2.776,0,0,1,.323-1.359,3.06,3.06,0,0,1,.77-.939,4.673,4.673,0,0,1,1.3-.649,14.5,14.5,0,0,1,1.467-.445c.411-.1.982-.209,1.721-.337l1.829-.336V-26.38a3.364,3.364,0,0,0-.541-1.973,1.907,1.907,0,0,0-1.673-.77,1.973,1.973,0,0,0-1.493.529,1.873,1.873,0,0,0-.5,1.348,1.577,1.577,0,0,0,.3,1.034,1.018,1.018,0,0,0,.829.361,1.285,1.285,0,0,0,.411-.072,2.021,2.021,0,0,1-2.1,1.3,2.165,2.165,0,0,1-1.5-.529,1.782,1.782,0,0,1-.589-1.4,2.536,2.536,0,0,1,1.323-2.166,6.1,6.1,0,0,1,3.394-.867,7.269,7.269,0,0,1,4.2,1,3.617,3.617,0,0,1,1.406,3.165q0,.889-.024,2.478t-.022,2.383a3.635,3.635,0,0,0,.313,1.732,1.182,1.182,0,0,0,1.129.554,1.234,1.234,0,0,0,.6-.144m-5.462-5.559-1.252.264a3.772,3.772,0,0,0-2.044.987,2.67,2.67,0,0,0-.65,1.9,2.38,2.38,0,0,0,.433,1.539,1.467,1.467,0,0,0,1.2.529,1.964,1.964,0,0,0,1.6-.806,3.371,3.371,0,0,0,.66-2.082Z" transform="translate(34.101 29.581)" fill="currentColor"/>
          </g>
          <g id="Group_1381" data-name="Group 1381" transform="translate(128.843 2.022)">
            <path id="Path_1102" data-name="Path 1102" d="M-14.2-19.484l.242.072q-.506,4.355-3.9,4.356a3.466,3.466,0,0,1-2.6-.987,3.86,3.86,0,0,1-.937-2.864l.022-7.6h-1.442v-.216a5.821,5.821,0,0,0,2.936-1.565A6.445,6.445,0,0,0-18.175-31.3h.242v4.163h3.658l-.12.626h-3.537v7.629q0,2.094,1.516,2.094,1.732,0,2.213-2.7" transform="translate(22.818 31.3)" fill="currentColor"/>
          </g>
          <g id="Group_1382" data-name="Group 1382" transform="translate(138.709 5.944)">
            <path id="Path_1103" data-name="Path 1103" d="M-7.1-.754a2.066,2.066,0,0,0-1.442.482A1.582,1.582,0,0,0-9.071.955a1.453,1.453,0,0,0,.589,1.263,8.66,8.66,0,0,0,2.347.927,8.43,8.43,0,0,1,3.381,1.6,3.3,3.3,0,0,1,1,2.539,3.349,3.349,0,0,1-1.323,2.8,5.309,5.309,0,0,1-3.345,1.023,9.051,9.051,0,0,1-2.093-.277,8.9,8.9,0,0,0-1.47-.277,1.751,1.751,0,0,0-1.154.554L-11.4,6.658h.046q1.925,4.02,4.934,4.02a2.456,2.456,0,0,0,1.624-.53A1.8,1.8,0,0,0-4.162,8.68a1.452,1.452,0,0,0-.7-1.324,9.778,9.778,0,0,0-2.384-.89,7.216,7.216,0,0,1-3.091-1.577,3.421,3.421,0,0,1-.951-2.538,3.161,3.161,0,0,1,1.2-2.587A4.58,4.58,0,0,1-7.1-1.211a6.6,6.6,0,0,1,1.842.3A6.13,6.13,0,0,0-3.9-.61a2.133,2.133,0,0,0,1.3-.6V2.712h-.049A7.244,7.244,0,0,0-4.607.2,3.946,3.946,0,0,0-7.1-.754" transform="translate(11.404 1.211)" fill="currentColor"/>
          </g>
          <g id="Group_1383" data-name="Group 1383" transform="translate(149.657 5.944)">
            <path id="Path_1104" data-name="Path 1104" d="M-10.439-20.319a6.3,6.3,0,0,1-4.514-1.732,5.855,5.855,0,0,1-1.816-4.428,5.853,5.853,0,0,1,1.816-4.428,6.293,6.293,0,0,1,4.514-1.734A6.344,6.344,0,0,1-5.9-30.92a5.845,5.845,0,0,1,1.817,4.44A5.841,5.841,0,0,1-5.9-22.04a6.339,6.339,0,0,1-4.536,1.721m-1.949-1.938a2.094,2.094,0,0,0,1.949,1.505,2.12,2.12,0,0,0,1.961-1.505,10.8,10.8,0,0,0,.663-4.223,10.715,10.715,0,0,0-.663-4.211,2.118,2.118,0,0,0-1.961-1.493,2.092,2.092,0,0,0-1.949,1.493,10.829,10.829,0,0,0-.65,4.211,10.915,10.915,0,0,0,.65,4.223" transform="translate(16.769 32.641)" fill="currentColor"/>
          </g>
          <g id="Group_1384" data-name="Group 1384" transform="translate(163.279 5.944)">
            <path id="Path_1105" data-name="Path 1105" d="M0-.4H4.812V2.828A5.085,5.085,0,0,1,6.388.276,3.983,3.983,0,0,1,9.025-.638,3.5,3.5,0,0,1,11.814.495,4.486,4.486,0,0,1,12.8,3.549V8.82a4.382,4.382,0,0,0,.217,1.672,2.23,2.23,0,0,0,1.01.9v.048h-5.9V11.4a2.187,2.187,0,0,0,1-.891A4.648,4.648,0,0,0,9.338,8.82V3.549a3.016,3.016,0,0,0-.482-1.768A1.7,1.7,0,0,0,7.365,1.1a2.171,2.171,0,0,0-1.854.974,4.611,4.611,0,0,0-.7,2.731V8.82a4.436,4.436,0,0,0,.217,1.672,2.244,2.244,0,0,0,1.01.9v.048H.144V11.4a2.171,2.171,0,0,0,1-.891A4.64,4.64,0,0,0,1.347,8.82V2.274A3.96,3.96,0,0,0,1.1.6,2.551,2.551,0,0,0,0-.348Z" transform="translate(0 0.638)" fill="currentColor"/>
          </g>
        </g>
      </g>
      <g id="Group_1392" data-name="Group 1392" transform="translate(-21566 -1593.628)">
        <g id="Group_1385" data-name="Group 1385" transform="translate(0 0.001)">
          <path id="Path_1106" data-name="Path 1106" d="M-44.412-55.128a11.6,11.6,0,0,1-2.075-3.992l-1.329-4.1a9.583,9.583,0,0,0-3.3-4.763,11.554,11.554,0,0,0-6.169-1.894A15.056,15.056,0,0,0-48.943-72.3a7.637,7.637,0,0,0,3.429-6.731q0-4.914-3.659-7.219t-9.753-2.3H-71.345v1.331h9.6a7.85,7.85,0,0,1,5.965,2.177,8.59,8.59,0,0,1,2.072,6.169q0,4.044-2.1,6.067a8.212,8.212,0,0,1-5.939,2.023h-9.6v1.381h8.732A5.661,5.661,0,0,1-58.441-68a10.17,10.17,0,0,1,2.279,4.122l1.69,5.632a15.118,15.118,0,0,0,2.713,5.53h10.8v-.1a10.121,10.121,0,0,1-3.455-2.3" transform="translate(71.345 88.559)" fill="#ac9467"/>
        </g>
        <g id="Group_1386" data-name="Group 1386" transform="translate(26.611 0)">
          <path id="Path_1107" data-name="Path 1107" d="M-31.577-41.022l0,0-.742-1.924v0L-38.9-60.011a7.868,7.868,0,0,1-.664-2.918,3.3,3.3,0,0,1,2.1-2.867v-.1h-13.26v.1a8.332,8.332,0,0,1,1.97,2.049,20.359,20.359,0,0,1,1.766,3.583l12.135,30.616h.256l3.721-9.67Z" transform="translate(50.726 65.899)" fill="#ac9467"/>
        </g>
        <g id="Group_1387" data-name="Group 1387" transform="translate(45.4 0)">
          <path id="Path_1108" data-name="Path 1108" d="M-30.138-41.37h0l-.08-.231-.022-.065-6.552-18.9a9.206,9.206,0,0,1-.667-2.918q0-1.687,2.1-2.867v-.1H-48.414v.1a8.349,8.349,0,0,1,1.971,2.049,20.472,20.472,0,0,1,1.765,3.583l1.9,5.018-.006.015.766,2.048.006-.016,8.806,23.55h.2l3.56-9.263Z" transform="translate(48.414 66.458)" fill="#ac9467"/>
        </g>
        <g id="Group_1388" data-name="Group 1388" transform="translate(68.286 0)">
          <path id="Path_1109" data-name="Path 1109" d="M0,0V.1A4.441,4.441,0,0,1,2.66,4.3a7.289,7.289,0,0,1-.409,2.3L.5,11.318l.787,2.228,2.806-7.3C5.187,3.381,6.613,1.276,8.446.1V0Z" fill="#ac9467"/>
        </g>
      </g>
    </g>
  </svg>
</template>
