<template>
  <div
    class="page-overlay"
    :class="{ '-showing': current !== null }"
  />
</template>

<script setup>
import useOverlayGlobal from '#/useOverlayGlobal.js'

const { current } = useOverlayGlobal()
</script>
