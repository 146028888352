<template>
  <div
    ref="el"
    class="anim-three-to-rect"
  >
    <div
      :class="{ '-show': show[0] }"
      class="-sqr"
    />
    <div
      :class="{ '-show': show[1] }"
      class="-sqr"
    />
    <div
      :class="{ '-show': show[2] }"
      class="-sqr"
    />
    <div
      :class="{ '-show': show[3] }"
      class="-rect"
    />
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import useInView from '../../composables/useInView.js'

const el = ref(null)
const show = ref([false, false, false, false])
const { inView } = useInView()




onMounted(() => {
  inView(el, () => {
    for (let i = 0; i < 4; i++) {
      setTimeout(() => {
        show.value[i] = true
      }, i * 400)
    }
  })
})

</script>
