<template>
  <div
    class="-animated-squares"
    :class="animFrameClass"
  >
    <div class="-large"></div>
    <div class="-hi1"></div>
    <div class="-hi2"></div>
    <div class="-hi3"></div>
    <div class="-lo1"></div>
    <div class="-lo2"></div>
    <div class="-lo3"></div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'

const animFrameClass = ref('')
onMounted(() => {
  for (let i = 1; i <= 3; i++) {
    setTimeout(() => {
      animFrameClass.value = '-frame' + i
    }, i * 300 + 500)
  }
})

</script>
