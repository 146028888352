<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.329em"
    height="11.525em"
    viewBox="0 0 502.447 238.023"
  >
    <g transform="translate(0 0.004)">
      <path
        d="M105.013,130.351q-7.553-8.365-13.586-26.14l-8.7-26.82Q75.675,56.934,61.092,46.2,46.509,35.486,20.7,33.8q32.18-.656,54.646-15.926Q97.789,2.633,97.8-26.2q0-32.181-23.963-47.27Q49.852-88.554,9.971-88.559H-71.345v8.713H-8.47q25.477,0,39.059,14.256T44.157-25.2q0,26.481-13.736,39.726Q16.665,27.778-8.47,27.776H-71.345v9.04h57.178q18.113,0,27.322,9.22t14.92,26.988L39.139,109.9q8.7,26.83,17.766,36.211h70.731v-.67q-15.084-6.7-22.623-15.091"
        transform="translate(71.345 88.559)"
        fill="currentColor"
      />
    </g>
    <g transform="translate(174.25 0)">
      <path
        d="M74.664,97l-.012.032-4.86-12.6-.007-.032L26.711-27.345q-4.364-10.723-4.351-19.11,0-11.394,13.739-18.772V-65.9H-50.726v.672a54.56,54.56,0,0,1,12.9,13.415q5.2,7.709,11.564,23.461L53.2,172.124h1.678L79.24,108.8Z"
        transform="translate(50.726 65.899)"
        fill="currentColor"
      />
    </g>
    <g transform="translate(297.284 0)">
      <path
        d="M71.261,97.82h0l-.524-1.51-.143-.425L27.689-27.906q-4.364-12.069-4.368-19.108,0-11.046,13.754-18.772v-.672H-48.414v.672A54.674,54.674,0,0,1-35.508-52.371q5.195,7.709,11.559,23.461L-11.533,3.952l-.04.1,5.018,13.408.042-.1L51.152,171.565h1.337L75.8,110.909Z"
        transform="translate(48.414 66.458)"
        fill="currentColor"
      />
    </g>
    <g transform="translate(447.143 0.001)">
      <path
        d="M0,0V.672C12,6.9,17.42,16.1,17.42,28.162a47.728,47.728,0,0,1-2.677,15.091L3.285,74.11,8.437,88.7,26.81,40.908C33.966,22.136,43.305,8.353,55.3.672V0Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
