<template>
  <div
    class="resize-caption-hover absolute inset-0 dark-transparent-bg"
    :class="{ '-bg-on': resizingCaptionState.abstractIsShowing.value }"
    @mouseenter="mouseIn"
    @mouseleave="mouseOut"
  >
    <ResizingCaption
      :state="resizingCaptionState"
      :title="title"
      :abstract="abstract"
      :cta="cta"
    />
  </div>
</template>
<script setup>
import ResizingCaption from '../widgets/ResizingCaption.vue'
import ResizingCaptionState from '../../classes/ResizingCaptionState.js'


defineProps({
  title: {
    type: String,
    required: true,
  },
  abstract: {
    type: String,
    required: true,
  },
  cta: {
    type: Object,
    default: () => ({ href: '#', text: 'Explore' }),
  },
})


const resizingCaptionState = new ResizingCaptionState()

const mouseIn = () => {
  resizingCaptionState.maximise()
}
const mouseOut = () => {
  resizingCaptionState.minimise()
}

resizingCaptionState.mountedCallback = () => {
  resizingCaptionState.minimise()
}

</script>
