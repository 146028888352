<template>
  <div class="slide-index-scrollbar">
    <div
      ref="trackEl"
      class="-track"
    >
      <div
        ref="handleEl"
        class="-handle"
        :style="`width:${100 / state.slides.length}%;left:${state.scrollbarPos.value}px`"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'

const props = defineProps({
  state: {
    type: Object,
    required: true,
  },
})

const trackEl = ref(null)
const handleEl = ref(null)
const mouseStart = ref(null)

let windowRange = null

props.state.addScrollBar(trackEl, handleEl)

onMounted(() => {
  document.addEventListener('mousemove', e => {
    if (mouseStart.value !== null) {
      const offset = e.pageX - mouseStart.value
      props.state.scrollbarPos.value = props.state.scrollbarOrigin + offset
      if (props.state.scrollbarPos.value < 0) {
        props.state.scrollbarPos.value = 0
      }
      if (props.state.scrollbarPos.value > props.state.scrollBarTrackRange) {
        props.state.scrollbarPos.value = props.state.scrollBarTrackRange
      }
      props.state.scrollWindow.value.scroll({
        top: 0,
        left: windowRange * props.state.scrollbarPos.value / props.state.scrollBarTrackWidth,
      })
    }
  })
  handleEl.value.addEventListener('mousedown', e => {
    mouseStart.value = e.pageX
    props.state.scrollbarOrigin = props.state.scrollbarPos.value
    props.state.scrollBarTrackWidth = trackEl.value.offsetWidth
    props.state.scrollBarTrackRange = trackEl.value.offsetWidth - handleEl.value.offsetWidth
    windowRange = props.state.slides[0].el.offsetWidth * props.state.slides.length
  })
  document.addEventListener('mouseup', e => {
    mouseStart.value = null
  })
})

</script>
