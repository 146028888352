<template>
  <span
    @click="scrollDown"
  >
    <slot />
  </span>
</template>
<script setup>
const props = defineProps({
  elId: {
    type: String,
    required: true,
  },
})
const scrollDown = () => {
  const el = document.getElementById(props.elId)
  if (el) {
    const y = el.getBoundingClientRect().top + window.scrollY;
    window.scroll({
      top: y,
      behavior: 'smooth',
    });
  } else {
    console.error('no element with id', props.elId)
  }
}
</script>
