<template>
  <header class="search-bar">
    <div class="center-frame">
      <div class="max-w-3xl mb-20">
        <h2 class="h3">
          {{ title }}
        </h2>
      </div>
      <form
        class="-form-line"
        method="get"
      >
        <div class="-inputs">
          <div
            v-for="(inp, i) in inputs"
            :key="i"
            class="-inp"
          >
            <select-field
              v-if="inp.type === 'select' || inp.type === 'multiselect'"
              :init-val="inp?.initVal || ''"
              :multi="inp.type === 'multiselect'"
              :options="inp.options"
              :all-option="'allOption' in inp ? inp.allOption : null"
              :field-name="inp.name"
              class="-search"
              label-class="pb-2 w-full justify-between text-blueDark"
            />
            <input
              v-else
              :name="inp.name"
              :type="inp.type"
              :value="inp?.initVal || ''"
              :placeholder="inp.placeholder"
              class="border-b border-blueDark p pb-2"
            />
          </div>
        </div>
        <button
          type="submit"
          class="button -med"
        >
          Search
        </button>
        <button
          type="button"
          class="text-greyMed flex gap-2 items-center hover:text-blueDark"
          @click.prevent="clear"
        >
          <SvgIconX />
          <span>Clear</span>
        </button>
      </form>
    </div>
  </header>
</template>
<script setup>
import SelectField from './SelectField.vue'
import SvgIconX from '../svg/IconX.vue'

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  inputs: {
    type: Array,
    required: true,
  },
})

const clear = () => window.location = window.location.pathname
</script>
