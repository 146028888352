<template>
  <section class="block-interested-in">
    <form
      :class="{'center-frame' : centerFrame}"
      method="get"
    >
      <h3 class="flex gap-3 phone-only:flex-col tablet-up:items-center phone-only:gap-1 phone-only:items-start phone-only:mb-8">
        <span class="border-b-2 border-transparent">I'm interested in</span>
        <select-field
          field-name="interested_in"
          :init-val="options[0].id"
          :options="options"
          @selected="value => selectPrimary(value)"
        ></select-field>
      </h3>
      <h3
        v-show="secondaryOptions.length"
        class="flex gap-3 phone-only:flex-col tablet-up:items-center phone-only:gap-1 phone-only:items-start"
      >
        <span class="border-b-2 border-transparent">and need help with</span>
        <select-field
          field-name="help_with"
          :options="secondaryOptions"
          @selected="value => selectSecondary(value)"
        ></select-field>
      </h3>
      <button
        type="submit"
        class="button -med mt-6 px-12"
        @click.prevent="go"
      >
        Take me there
      </button>
    </form>
  </section>
</template>

<script setup>
import SelectField from './SelectField.vue';
import { ref } from 'vue';

const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  centerFrame: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const primarySelectedOption = ref(null),
  secondarySelectedOption = ref(null),
  secondaryOptions = ref([]);

const selectPrimary = value => {
  primarySelectedOption.value = value;
  secondarySelectedOption.value = value?.entryOptions[0]?.id || null;
  secondaryOptions.value = value?.entryOptions || [];
};

const selectSecondary = value => {
  secondarySelectedOption.value = value;
};

const go = () => {
  window.location.href = secondarySelectedOption.value.id
    ? secondarySelectedOption.value.url
    : primarySelectedOption.value.url;
};
</script>
