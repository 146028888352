<template>
  <div
    :class="{ '-expand': isExpanded }"
    class="landing-footer tablet-up:border-b border-greyLight"
  >
    <div
      class="center-frame pt-20 tablet-up:pb-12"
      :style="expandableStyle()"
    >
      <div
        ref="expandHeightEl"
        class="-expand-height"
      >
        <div>
          <SvgLogoFull />
        </div>
        <div class="-right-col flex justify-between">
          <div class="-statement inline-block">
            <p
              v-if="statement !== null"
              class="max-w-md tablet-up:font-bold"
            >
              {{ statement }}
            </p>
          </div>
          <div class="-toggle inline-block">
            <p
              class="text-gold flex gap-2 cursor-pointer hover:text-textColor1 items-center"
              @click="toggle"
            >
              <span class="font-bold">Show {{ isExpanded ? 'less' : 'More' }}</span>
              <SvgChevDown class="text-14" />
            </p>
          </div>
        </div>
        <div class="-contact">
          <slot name="contact" />
        </div>
        <div class="-nav phone-only:-mt-4">
          <slot name="nav" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import SvgLogoFull from '~/svg/LogoFull.vue'
import SvgChevDown from '~/svg/ChevDown.vue'

defineProps({
  statement: {
    type: String,
    default: null,
  },
})

const expandHeightEl = ref(null)

const isExpanded = ref(false)

const toggle = () => {
  isExpanded.value = ! isExpanded.value
}
const expandableStyle = () => {
  if (isExpanded.value) {
    const height = expandHeightEl.value.offsetHeight + 180
    return `height: ${height}px`
  } else {
    return ''
  }
}

</script>
