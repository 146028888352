<template>
  <div
    ref="el"
    class="anim-single"
    :class="{ '-show': show }"
  />
</template>
<script setup>
import { ref, onMounted } from 'vue'
import useInView from '../../composables/useInView.js'

const el = ref(null)
const show = ref(false)
const { inView } = useInView()




onMounted(() => {
  inView(el, () => {
    show.value = true
  })
})

</script>
