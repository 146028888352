<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.4em"
    height="7.4em"
    viewBox="0 0 74 74"
  >
    <g
      id="Group_1451"
      data-name="Group 1451"
      transform="translate(-247.136 -495.136)"
    >
      <circle
        id="Ellipse_14"
        data-name="Ellipse 14"
        cx="37"
        cy="37"
        r="37"
        transform="translate(247.136 495.136)"
        fill="#ac9467"
      />
      <path
        id="Path"
        d="M0,0,18.632,11.466,0,22.931Z"
        transform="translate(278.531 520.542)"
        fill="#fff"
      />
    </g>
  </svg>
</template>
