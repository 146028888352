<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2em"
    height="2em"
    viewBox="0 0 20 20"
  >
    <path
      id="Linkedin"
      d="M18,0a2,2,0,0,1,2,2V18a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0ZM6.937,7.364H4.25V16H6.937Zm6.84-.215a2.824,2.824,0,0,0-2.542,1.395H11.2V7.364H8.622V16h2.685V11.727c0-1.126.215-2.217,1.613-2.217,1.378,0,1.4,1.288,1.4,2.29V16H17V11.263C17,8.938,16.5,7.149,13.777,7.149ZM5.566,3A1.564,1.564,0,1,0,7.132,4.564,1.565,1.565,0,0,0,5.566,3Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>
