<template>
  <section
    class="hero -slideshow text-white"
  >
    <div class="-bgs">
      <div
        v-for="(slide, i) in slides"
        :key="i"
        class="picture-inset"
      >
        <ResPicture
          v-bind="slide.bg"
          :class="{
            '-hide-left': i < activeSlide,
            '-hide-right': i > activeSlide,
          }"
        />
      </div>
    </div>
    <div class="-overlay" />
    <div class="-slides">
      <div
        v-for="(slide, i) in slides"
        :key="slide.id"
        class="-slide"
      >
        <div
          class="center-frame"
          :class="{
            '-hide-left': i < activeSlide,
            '-hide-right': i > activeSlide,
          }"
        >
          <div class="-spacer"></div>
          <article>
            <template v-if="i === 0">
              <h1 class="eyebrow1">
                A PREMIER GOLD COAST LEGAL FIRM
              </h1>
              <h2 class="h1-sml">
                {{ slide.title }}
              </h2>
            </template>
            <template v-else>
              <h2 class="eyebrow1">
                A PREMIER GOLD COAST LEGAL FIRM
              </h2>
              <h3 class="h1-sml">
                {{ slide.title }}
              </h3>
            </template>
            <p v-html="slide.content" />
            <template v-if="typeof slide.link !== 'undefined'">
              <a
                class="button -med -white mt-4"
                :href="slide.link.href"
              >{{ slide.link.text }}</a>
            </template>
          </article>
          <footer class="pb-12">
            <nav>
              <span
                v-for="(_, j) in slides"
                :key="j"
                :class="{ '-current': j === activeSlide }"
                @click="activate(j)"
              ><span></span></span>
            </nav>
            <ScrollDown>Discover our impact</ScrollDown>
          </footer>
        </div>
      </div>
    </div>
    <AnimatedSquares />
  </section>
</template>
<script setup>
import { ref } from 'vue'
import ScrollDown from './widgets/ScrollDown.vue'
import AnimatedSquares from './widgets/AnimatedSquares.vue';
import ResPicture from './widgets/ResPicture.vue';

const props = defineProps({
  auto: {
    type: Boolean,
    default: false,
  },
  interval: {
    type: Number,
    default: 20,
  },
  slides: {
    type: Array,
    required: true,
  },
})

const activeSlide = ref(0)
const inTransition = ref(false)
const timeoutHandle = ref(null)

const activate = (i) => {
  if (inTransition.value) {
    return false
  }
  inTransition.value = true
  activeSlide.value = i
  setTimeout(() => {
    inTransition.value = false
  }, 1500)
  if (props.auto) {
    setNextChange()
  }
}


const setNextChange = () => {
  clearTimeout(timeoutHandle.value)
  timeoutHandle.value = setTimeout(() => {
    let nextI = activeSlide.value + 1
    if (nextI >= props.slides.length) {
      nextI -= props.slides.length
    }
    activate(nextI)
  }, props.interval * 1000)
}

if (props.auto) {
  setNextChange()
}



</script>
