<template>
  <svg
    width="2em"
    height="2em"
    viewBox="0 0 119 119"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
  >
    <path
      d="M59.297,2.136C90.766,2.136 116.315,27.685 116.315,59.154C116.315,90.623 90.766,116.172 59.297,116.172C27.828,116.172 2.279,90.623 2.279,59.154C2.279,27.685 27.828,2.136 59.297,2.136ZM63.575,57.01L63.575,28.437L55.165,28.437L55.165,61.411L88.429,90.184L94.08,83.381L63.575,57.01Z"
      style="fill:currentColor"
    />
  </svg>
</template>
