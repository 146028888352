<template>
  <svg
    width="0.6em"
    height="0.5em"
    viewBox="0 0 24 20"
    xmlns="http://www.w3.org/2000/svg"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
  >
    <g transform="matrix(1,0,0,1,0,0.25)">
      <path
        d="M0.25,5.25L1.75,3.75L12,14L22.25,3.75L23.75,5.25L12,17L0.25,5.25Z"
        style="fill:currentColor;"
      />
    </g>
  </svg>
</template>
