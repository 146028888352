<template>
  <div
    ref="dropContainer"
    class="-drop"
  >
    <div
      ref="dropHeight"
      class="center-frame"
    >
      <aside>
        <div
          v-for="(tab, i) in nodes"
          :key="`tab${i}`"
          class="-tab"
          :class="{
            '-current': currentTabFast === i,
            '-hidden': tabCascadeHide[i],
            '-mobileOpen': currentTabMobile === i,
          }"
          @click="changeCurrentTab(i)"
        >
          <div class="flex">
            <h5>
              {{ tab.title }}
            </h5>
            <ChevRightCircled />
          </div>
          <nav
            :ref="el => mobileSubNavContainers.push(el)"
            class="-mobile-sub-nav"
          >
            <div
              :ref="el => mobileSubNavHeights.push(el)"
              class="-height"
            >
              <a
                v-for="(link, j) in nodes[i].children"
                :key="`${j} ${i}`"
                :href="link.url"
                class="font-bold"
              >{{ link.title }}</a>
            </div>
          </nav>
        </div>
        <hr
          :class="{ '-hidden' : tabCascadeHide[nodes.length] }"
        />
      </aside>
      <section>
        <div>
          <h5 class="-fade-early">
            <a
              :href="nodes[currentTab].url"
              class="gap-4 chev-hover-right"
            >
              <span>View all {{ nodes[currentTab].title }} services</span>
              <SvgChevRight class="text-28"></SvgChevRight>
            </a>
          </h5>
          <nav>
            <a
              v-for="(link, j) in nodes[currentTab].children"
              :key="`link${j}`"
              :href="link.url"
              :class="{ '-hidden' : linkCascadeHide[j] }"
            >{{ link.title }}</a>
          </nav>
        </div>
      </section>
    </div>
  </div>
</template>
<script setup>
import { onMounted } from 'vue'
import ChevRightCircled from './svg/ChevRightCircled.vue'
import useTopNavDrop from '../composables/useTopNavDrop.js'
import MobileCtaAndSearch from './widgets/MobileCtaAndSearch.vue'
import SvgChevRight from './svg/ChevRight.vue'

const props = defineProps({
  nodes: {
    type: Array,
    required: true,
  },
})


const {
  tabCascadeHide,
  dropContainer,
  dropHeight,
  currentTabFast,
  currentTabMobile,
  linkCascadeHide,
  dropTransitionEnd,
  mobileSubNavContainers,
  mobileSubNavHeights,
  changeCurrentTab,
  currentTab,
} = useTopNavDrop()


onMounted(() => {
  setTimeout(() => {
    if (typeof dropContainer === 'undefined') {
      return
    }
    dropContainer?.value.addEventListener('transitionend', dropTransitionEnd)
  }, 100)
})


</script>
