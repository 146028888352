<template>
  <svg
    viewBox="0 0 11 10"
    width="1.3em"
    height="0.75em"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(6.68606e-17,1.09192,-1.09192,6.68606e-17,17.5087,-9.17465)">
      <path
        d="M8.59,14.817L12.407,11L8.59,7.175L9.765,6L14.765,11L9.765,16L8.59,14.817Z"
        style="fill:currentColor;"
      />
    </g>
  </svg>
</template>
