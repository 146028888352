import { ref } from 'vue'

const currentFocusEl = ref(null)
let _clickOffCallback = null
let justFocusedNow = false

const focus = (el, clickOffCallback = null) => {
  if ( ! el) {
    console.error('pass in the focusable element')
  }
  setTimeout(() => {
    currentFocusEl.value = el
    justFocusedNow = true
    _clickOffCallback = clickOffCallback
  }, 100)
  setTimeout(() => {
    justFocusedNow = false
  }, 500)
}

const close = () => {
  currentFocusEl.value = null
  _clickOffCallback = null
}

const clear = (el) => {
  if (el === currentFocusEl.value) {
    currentFocusEl.value = null
    _clickOffCallback = null
  }
}

window.addEventListener('click', (e) => {
  if (currentFocusEl.value !== null && ! justFocusedNow) {
    if ( ! currentFocusEl.value.contains(e.detail?.target || e.target)) {
      if (_clickOffCallback !== null) {
        _clickOffCallback()
      }
      close()
    }
  }
})


export default function useClickOff() {
  return {
    currentFocusEl,
    focus,
    close,
    clear,
  }
}
