<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="57 41 26 30"
    xml:space="preserve"
    width="4em"
    height="5em"
  >
    <path
      style="fill:currentColor"
      d="M60,68.2c-1.3-2.2-2.2-4.5-2.7-6.9c-0.4-2.4-0.5-4.8-0.1-7.2c0.4-2.4,1.1-4.6,2.3-6.8c1.2-2.2,2.8-4.1,4.7-5.8
		l2.7,1.7c0.5,0.3,0.7,0.7,0.6,1.2c-0.1,0.5-0.2,0.8-0.5,1.1c-0.7,0.9-1.4,2-1.9,3.4c-0.6,1.3-0.9,2.8-1.1,4.3
		c-0.2,1.6-0.1,3.2,0.2,4.9c0.3,1.7,0.9,3.3,1.9,5c0.4,0.7,0.5,1.3,0.4,1.8S66,65.8,65.4,66L60,68.2z M73.9,68.2
		c-1.3-2.2-2.2-4.5-2.7-6.9c-0.5-2.4-0.5-4.8-0.1-7.2c0.4-2.4,1.1-4.6,2.3-6.8c1.2-2.2,2.8-4.1,4.7-5.8l2.7,1.7
		c0.5,0.3,0.7,0.7,0.6,1.2c-0.1,0.5-0.2,0.8-0.5,1.1c-0.7,0.9-1.4,2-1.9,3.4c-0.6,1.3-0.9,2.8-1.1,4.3c-0.2,1.6-0.1,3.2,0.2,4.9
		c0.3,1.7,0.9,3.3,1.9,5c0.4,0.7,0.5,1.3,0.4,1.8c-0.2,0.5-0.6,0.9-1.2,1.2L73.9,68.2z"
    />
  </svg>
</template>
