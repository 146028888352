<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2.4em"
    height="2.4em"
    viewBox="0 0 24 24"
  >
    <path
      d="M15,9.667A5.335,5.335,0,1,0,20.333,15,5.336,5.336,0,0,0,15,9.667M5.667,19H3v5.333A2.675,2.675,0,0,0,5.667,27H11V24.333H5.667m0-18.667H11V3H5.667A2.675,2.675,0,0,0,3,5.667V11H5.667M24.333,3H19V5.667h5.333V11H27V5.667A2.675,2.675,0,0,0,24.333,3m0,21.333H19V27h5.333A2.675,2.675,0,0,0,27,24.333V19H24.333"
      transform="translate(-3 -3)"
      fill="currentcolor"
    />
  </svg>
</template>
