<template>
  <div
    ref="el"
    class="picture-inset row-start-2 row-end-4 col-start-6 col-end-[21] out-from-right phone-only:col-start-2 phone-only:col-end-[25]"
    :class="{ '-anim-init': animInit }"
    @click="handleClick"
  >
    <slot></slot>
    <div class="absolute inset-0 bg-black opacity-50"></div>
    <div class="absolute inset-0 flex flex-col justify-center items-center cursor-pointer">
      <IconPlay class="text-10" />
      <h6 class="text-white med-caps mt-6">
        Watch the video
      </h6>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import IconPlay from '../svg/IconPlay.vue'
import useInView from '../../composables/useInView.js'
import GLightbox from 'glightbox'

const animInit = ref(true)
const el = ref(null)
const { inView } = useInView()


const props = defineProps({
  host: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: true,
  },
})

const lightbox = new GLightbox({
  elements: [
    {
      'href': props.host === 'vimeo' ? 'https://vimeo.com/'+props.id : 'https://www.youtube.com/watch?v='+props.id,
      'type': 'video',
      'source': props.host, // 'vimeo' or 'youtube' ('local' not available)
      'width': 900,
    },
  ],
  autoplayVideos: true,
})

const handleClick = () => {
  console.log('clicked', lightbox)
  lightbox.open()
}

onMounted(() => {
  inView(el, () => {
    animInit.value = false
  })
})

</script>
