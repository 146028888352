<template>
  <div>
    <h2
      class="med-caps leading-normal font-bold pb-2 phone-only:pt-6 flex justify-between items-center"
      :class="{ 'text-gold': isExpandedMobile }"
    >
      <slot name="title" />
      <SvgChevDown
        class="tablet-up:hidden text-24 text-gold"
        :class="{ 'rotate-180': isExpandedMobile }"
        @click="toggle"
      />
    </h2>
    <div
      class="overflow-hidden height-transition"
      :style="expandableStyle()"
      :class="{ 'phone-only:h-0': isExpandedMobile === false }"
    >
      <nav
        ref="expandHeightEl"
        class="flex flex-col gap-2 phone-only:pb-5"
      >
        <slot name="links" />
      </nav>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import SvgChevDown from '~/svg/ChevLightDown.vue'

const isExpandedMobile = ref(false)
const expandHeightEl = ref(null)

const toggle = () => {
  isExpandedMobile.value = !isExpandedMobile.value
  console.log(isExpandedMobile.value)
}
const expandableStyle = () => {
  if (isExpandedMobile.value) {
    const height = expandHeightEl.value.offsetHeight
    return `height: ${height}px`
  } else {
    return ''
  }
}
</script>
