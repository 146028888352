<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2.4em"
    height="2.4em"
    viewBox="0 0 24 24"
  >
    <path
      d="M24.333,3H5.667A2.675,2.675,0,0,0,3,5.667V24.333A2.675,2.675,0,0,0,5.667,27H24.333A2.675,2.675,0,0,0,27,24.333V5.667A2.675,2.675,0,0,0,24.333,3M11,21.667H8.333V12.333H11v9.333m5.333,0H13.667V8.333h2.667V21.667m5.333,0H19V16.333h2.667Z"
      transform="translate(-3 -3)"
      fill="currentColor"
    />
  </svg>
</template>
