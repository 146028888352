<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2.6902em"
    height="2.6902em"
    viewBox="0 0 26.902 26.902"
  >
    <path
      id="Border"
      d="M15.314,13.451,26.9,25.04,25.04,26.9,13.451,15.314,1.862,26.9,0,25.04,11.589,13.451,0,1.862,1.862,0,13.451,11.589,25.04,0,26.9,1.862Z"
      fill="currentColor"
    />
  </svg>
</template>
