<template>
  <div
    ref="el"
    class="modal-enquiry fixed inset-0 z-mobileEnquiry bg-overlay flex justify-center pt-[8vh] phone-only:pt-[6vw]"
    style="display: none"
    :class="{ '-show': tab > 0}"
    @click="tab = 0"
  >
    <div
      ref="modalWindow"
      class="-window inline-block w-3/4 max-w-[752px] bg-white max-h-[84vh] overflow-scroll phone-only:max-w-none phone-only:w-[88%] phone-only:max-h-full phone-only:h-[93vh] overflow-x-hidden overscroll-contain"
      @click.stop="$event => redispatchClick($event)"
    >
      <header
        ref="header"
        class="pt-[58px] relative pb-8 phone-only:pt-8 phone-only:pb-0"
      >
        <div
          class="-close absolute top-9 right-9 cursor-pointer opacity-20 hover:opacity-100 phone-only:top-6 phone-only:right-6"
          @click="tab = 0"
        >
          <IconLgX class="text-10 phone-only:text-8" />
        </div>
        <h3 class="font-bold mb-[45px] px-20 phone-only:text-[30px] phone-only:px-[24px]">
          Ready to talk?
        </h3>
        <nav class="flex px-[60px] border-b border-gold phone-only:hidden">
          <span
            v-for="(tabLabel, i) in tabs"
            :key="i"
            class="med-caps py-4 px-5 cursor-pointer"
            :class="{ 'bg-gold text-white': i + 1 === tab }"
            @click="tab = i + 1"
          >{{ tabLabel }}</span>
        </nav>
      </header>
      <article>
        <div
          class="small-accordion tablet-up:hidden pb-12"
        >
          <div
            class="-expand-head"
            :class="{'-open': sectionMobile === 1}"
            @click="sectionMobile = sectionMobile === 1 ? 0 : 1"
          >
            <h4>Enquire</h4>
            <ChevLightDown
              class="text-28"
            />
          </div>
          <div
            :ref="sectionsContentMobile[0]"
            class="-expand-body-outer"
          >
            <div class="-expand-body-inner">
              <div class="-pad-x">
                <h4 class="font-normal">
                  Fill out the form below and a team member will get back to you shortly
                </h4>
                <interested-in-input-selector
                  class="mb-12 mt-8 phone-only:pt-4"
                  :options="interestedInOptions"
                  :center-frame="false"
                  :interested-in-input="interestedInInput"
                  :help-with-input="needHelpWithInput"
                />
              </div>
              <slot></slot>
            </div>
          </div>
          <div
            class="-expand-head"
            :class="{'-open': sectionMobile === 2}"
            @click="sectionMobile = sectionMobile === 2 ? 0 : 2"
          >
            <h4 class="font-normal">
              Phone
            </h4>
            <ChevLightDown
              class="text-28"
            />
          </div>
          <div
            :ref="sectionsContentMobile[1]"
            class="-expand-body-outer"
          >
            <div class="-expand-body-inner">
              <SectionPhone
                :phone="phone"
                class="-pad-x"
              />
            </div>
          </div>
          <div
            class="-expand-head"
            :class="{'-open': sectionMobile === 3}"
            @click="sectionMobile = sectionMobile === 3 ? 0 : 3"
          >
            <h4 class="font-normal">
              Book Appointment
            </h4>
            <ChevLightDown
              class="text-28"
            />
          </div>
          <div
            :ref="sectionsContentMobile[2]"
            class="-expand-body-outer"
          >
            <div class="-expand-body-inner">
              <div class="-pad-x">
                <SectionSchedule />
              </div>
            </div>
          </div>
          <div
            class="-expand-head"
            :class="{'-open': sectionMobile === 4}"
            @click="sectionMobile = sectionMobile === 4 ? 0 : 4"
          >
            <h4 class="font-normal">
              Begin Online
            </h4>
            <ChevLightDown
              class="text-28"
            />
          </div>
          <div
            :ref="sectionsContentMobile[3]"
            class="-expand-body-outer"
          >
            <div class="-expand-body-inner">
              <div class="-pad-x">
                <SectionBeginOnline
                  @opensettifyopts="openSettifyOptsMobile"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          ref="scrollWindow"
          class="snap-x snap-mandatory overflow-x-scroll hide-scrollbar phone-only:hidden"
        >
          <div class="flex w-[400%]">
            <section class="inline-block w-1/3 snap-center">
              <div
                :ref="(el) => sectionContent.push(el)"
                class="pt-5"
              >
                <h5 class="font-light mb-10 px-20">
                  Fill out the form bellow and a team member will get back to you shortly
                </h5>
                <interested-in-input-selector
                  class="reduced-heading-sizes px-20 pb-12"
                  :options="interestedInOptions"
                  :center-frame="false"
                  :interested-in-input="interestedInInput"
                  :help-with-input="needHelpWithInput"
                ></interested-in-input-selector>
                <slot></slot>
              </div>
            </section>
            <section class="inline-block w-1/3 snap-center">
              <div
                :ref="(el) => sectionContent.push(el)"
                class="px-20 pt-6 pb-[65px]"
              >
                <h5 class="font-bold">
                  Call and speak to a trusted advisor today
                </h5>
                <h3
                  v-if="phone"
                  class="text-gold font-bold"
                >
                  <a :href="`tel:${phone}`">{{ phone }}</a>
                </h3>
              </div>
            </section>
            <section class="inline-block w-1/3 snap-center">
              <div
                :ref="(el) => sectionContent.push(el)"
                class="px-20 pt-6 pb-[65px]"
              >
                <SectionSchedule />
              </div>
            </section>
            <section class="inline-block w-1/3 snap-center">
              <div
                :ref="(el) => sectionContent.push(el)"
                class="px-20 pt-6 pb-[65px]"
              >
                <SectionBeginOnline
                  @opensettifyopts="openSettifyOptsDesktop"
                />
              </div>
            </section>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import useModalEnquiry from '#/useModalEnquiry.js';
import SectionPhone from './enquiry/SectionPhone.vue';
import SectionSchedule from './enquiry/SectionSchedule.vue';
import SectionBeginOnline from './enquiry/SectionBeginOnline.vue';
import IconLgX from '~/svg/IconLgX.vue';
import ChevLightDown from '~/svg/ChevLightDown.vue';
import InterestedInInputSelector from '~/widgets/InterestedInInputSelector.vue';

const tabs = [
  'Enquire',
  'Phone',
  'Book appointment',
  'Begin online',
];

const props = defineProps({
  phone: {
    type: String,
    default: null,
  },
  interestedInOptions: {
    type: Array,
    required: true,
  },
});

const el = ref(null)

const { tab } = useModalEnquiry();
const sectionMobile = ref(0);
const scrollWindow = ref(null);
const timeoutHandle = ref(null);
const sectionContent = ref([]);
const sectionsContentMobile = {
  0: ref(null),
  1: ref(null),
  2: ref(null),
  3: ref(null),
};
const header = ref(null);
const modalWindow = ref(null);

let interestedInInput = ref(null),
  needHelpWithInput = ref(null);

const opensectionsContentMobile = () => {
  for (const i in sectionsContentMobile) {
    const section = sectionsContentMobile[i].value;
    if (sectionMobile.value === 1 + parseInt(i)) {
      section.style.height = section.firstChild.offsetHeight + 'px';
      if (i > 0) {
        setTimeout(() => {
          modalWindow.value.scrollTo({
            top: 40 + (i * 96),
            behavior: 'smooth',
          })
        }, 500)
      }
    } else {
      section.style.height = 0;
    }
  }
};

onMounted(() => {
  scrollWindow.value.addEventListener('scroll', (event) => {
    clearTimeout(timeoutHandle.value);
    timeoutHandle.value = setTimeout(() => {
      const tN = Math.round(event.target.scrollLeft / event.target.offsetWidth) + 1;
      if (tN !== tab.value) {
        tab.value = tN;
      }
    }, 100);
  });
  opensectionsContentMobile();
  setTimeout(() => {
    el.value.style.display = 'flex'
  }, 500)
});

watch(tab, (newVal, oldVal) => {
  if (newVal === 0) {
    return;
  }
  if (newVal !== oldVal) {
    setTimeout(() => {
      // console.log('scrollWindow.value', scrollWindow.value, (newVal - 1) * scrollWindow.value.offsetWidth)
      scrollWindow.value.scroll({
        left: (newVal - 1) * scrollWindow.value.offsetWidth,
        behavior: 'smooth',
      });
      const bodyHeight = sectionContent.value[newVal - 1].offsetHeight;
      if (bodyHeight > 0) {
        const height = header.value.offsetHeight + bodyHeight;
        modalWindow.value.style.height = `${height}px`;
      }
      bindDomDependencies()

    }, 100);
  } else {
    console.log('%c Should this ever happen? ', 'font-size: 2rem;color: #fab', newVal, oldVal);
  }
});

watch(sectionMobile, (newVal, oldVal) => {
  opensectionsContentMobile();
});

const bindDomDependencies = () => {
  interestedInInput.value = document.querySelectorAll('select[data-fui-id="readyToTalkEnquiry-imInterestedIn"]'),
  needHelpWithInput.value = document.querySelectorAll(
    'select[data-fui-id="readyToTalkEnquiry-andNeedHelpWith"]');
}

const redispatchClick = (e) => {
  window.dispatchEvent(new CustomEvent('click', {
    detail: {
      target: e.target,
    },
  }))
}

const openSettifyOptsMobile = () => {
  modalWindow.value.scrollTo({
    top: 500,
    behavior: 'smooth',
  })
}
const openSettifyOptsDesktop = () => {
  modalWindow.value.scrollTo({
    top: 70,
    behavior: 'smooth',
  })
}



</script>
