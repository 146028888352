<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2.6667em"
    height="2.4em"
    viewBox="0 0 26.667 24"
  >
    <path
      d="M12.667,20.333V19H3.333v5.333A2.657,2.657,0,0,0,6,27H24.667a2.657,2.657,0,0,0,2.667-2.667V19H18v1.333H12.667M26,8.333H20.667V5.667L18,3H12.667L10,5.667V8.333H4.667A2.675,2.675,0,0,0,2,11v4a2.657,2.657,0,0,0,2.667,2.667h8V15H18v2.667h8A2.675,2.675,0,0,0,28.667,15V11A2.675,2.675,0,0,0,26,8.333m-8,0H12.667V5.667H18Z"
      transform="translate(-2 -3)"
      fill="currentColor"
    />
  </svg>
</template>
