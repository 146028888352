<template>
  <div
    :ref="el => dropHeightMobileLower[i] = el"
    class="top-nav-drop-mobile-level"
  >
    <div
      v-if="i > 1"
      class="-back"
      @click.stop="mobileDrillBack"
    >
      <div class="center-frame">
        <ChevRightCircled />
        <h5>Back</h5>
      </div>
    </div>
    <div
      class="center-frame"
      :style="`max-height:${mobileNavMaxHeight}`"
    >
      <div
        v-for="node in nodes"
        :key="node.id"
        class="-tapable"
      >
        <a
          class="flex"
          :href="node.url"
          :target="node.contentId === 'begin-online' ? '_blank' : '_self'"
          :rel="node.contentId === 'begin-online' ? 'noreferrer noopener' : null"
          @click="tap(node, $event)"
        >
          <h5>{{ node.title }}</h5>
          <ChevRightCircled
            v-if="hasDropAny(node, i)"
          />
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
import ChevRightCircled from './svg/ChevRightCircled.vue'
import useTopNavDrop from '#/useTopNavDrop.js'

const {
  dropHeightMobileLower,
  mobileDrilldown,
  mobileDrilldownBeginOnline,
  mobileDrillBack,
  mobileNavMaxHeight,
  hasDropLg,
  hasDropAny,
  hasDropBeginOnline,
} = useTopNavDrop()

const props = defineProps({
  nodes: {
    type: Array,
    required: true,
  },
  i: {
    type: Number,
    required: true,
  },
})



const tap = (node, e) => {
  if (hasDropLg(node, props.i)) {
    e.preventDefault()
    mobileDrilldown(props.i, node)
  } else if (hasDropBeginOnline(node)) {
    mobileDrilldownBeginOnline(props.i, node)
  }
}


</script>
