<template>
  <div
    class="flex gap-3 items-center cursor-pointer phone-only:hidden"
    @click="scrollDown"
  >
    <p class="mb-0">
      <slot />
    </p>
    <SvgChevDown class="text-11" />
  </div>
</template>
<script setup>
import SvgChevDown from '../svg/ChevDown.vue'

const scrollDown = () => {
  window.scroll({
    top: window.innerHeight - 200,
    behavior: 'smooth',
  })
}
</script>
