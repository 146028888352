// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
import useModalConfirmation from './composables/useModalConfirmation'
import useModalEnquiry from './composables/useModalEnquiry'

if (import.meta.hot) {
  import.meta.hot.accept(() => {})
}

import axios from 'axios'

window.axios = axios


import { createApp } from 'vue/dist/vue.esm-bundler'
import TopNav from '~/TopNav.vue'
import PageOverlay from '~/PageOverlay.vue'
import LandingFooter from '~/LandingFooter.vue'
import FooterNavCol from '~/FooterNavCol.vue'
import CtaFixed from '~/widgets/CtaFixed.vue'
import DesktopSearchDrop from '~/modals/DesktopSearchDrop.vue'
import ModalConfirmation from '~/modals/ModalConfirmation.vue'
import ModalEnquiry from '~/modals/ModalEnquiry.vue'
import OpenModalEnquiry from '~/widgets/OpenModalEnquiry.vue'
import AccordionPart from '~/widgets/AccordionPart.vue'

import BlockHeroSlideshow from '~/BlockHeroSlideshow.vue'
import AnimatedSquares from '~/widgets/AnimatedSquares.vue'
import BlockInterestedIn from '~/BlockInterestedIn.vue'
import BlockSlideIndexImg from '~/BlockSlideIndexImg.vue'
import BlockSlideIndexNoImg from '~/BlockSlideIndexNoImg.vue'
import BlockSlideIndexProfile from '~/BlockSlideIndexProfile.vue'
import BlockSlideIndexImgSimple from '~/BlockSlideIndexImgSimple.vue'

import SvgLogoRw from '~/svg/LogoRw.vue'
import SvgLogoFull from '~/svg/LogoFull.vue'

import SvgChevRight from '~/svg/ChevRight.vue'
import SvgChevLeft from '~/svg/ChevLeft.vue'
import SvgChevRightCircled from '~/svg/ChevRightCircled.vue'
import SvgChevLightDown from '~/svg/ChevLightDown.vue'
import SvgIconX from '~/svg/IconX.vue'
import SvgClock from '~/svg/Clock.vue'

import SvgPillarMedal from '~/svg/PillarMedal.vue'
import SvgPillarBriefcase from '~/svg/PillarBriefcase.vue'
import SvgPillarBook from '~/svg/PillarBook.vue'
import SvgPillarPeople from '~/svg/PillarPeople.vue'

import SvgSocialSquareFb from '~/svg/social/SquareFb.vue'
import SvgSocialSquareTw from '~/svg/social/SquareTw.vue'
import SvgSocialSquareLinkedIn from '~/svg/social/SquareLinkedIn.vue'

import SvgCoreValuesCenterFocus from '~/svg/core-values/CenterFocus.vue'
import SvgCoreValuesCashClock from '~/svg/core-values/CashClock.vue'
import SvgCoreValuesFlagCheckered from '~/svg/core-values/FlagCheckered.vue'
import SvgCoreValuesChartBox from '~/svg/core-values/ChartBox.vue'
import SvgCoreValuesCircleDot from '~/svg/core-values/CircleDot.vue'
import SvgCoreValuesCheckDecagram from '~/svg/core-values/CheckDecagram.vue'
import SvgIconFaqs from '~/svg/IconFaqs.vue'
import SvgIconPayments from '~/svg/IconPayments.vue'

import VideoThumb from '~/widgets/VideoThumb.vue'
import ResPicture from '~/widgets/ResPicture.vue'
import SearchBar from '~/widgets/SearchBar.vue'
import ResizeCaptionHover from '~/widgets/ResizeCaptionHover.vue'
import SelectField from '~/widgets/SelectField.vue'
import SelectFieldSml from '~/widgets/SelectFieldSml.vue'
import ScrollTo from '~/widgets/ScrollTo.vue'
import InterestedInNavigator from '~/widgets/InterestedInNavigator.vue'
import BlockContactVacancyApplication from '~/BlockContactVacancyApplication.vue'
import ScrollDown from '~/widgets/ScrollDown.vue'
import SocialLinks from '~/widgets/SocialLinks.vue'

import AnimThreeToRect from '~/anims/AnimThreeToRect.vue'
import AnimSingle from '~/anims/AnimSingle.vue'

const { tab } = useModalEnquiry()
const { showConfirmationModal } = useModalConfirmation()

const app = createApp({
  components: {
    TopNav,
    PageOverlay,
    LandingFooter,
    FooterNavCol,
    BlockHeroSlideshow,
    AnimatedSquares,
    CtaFixed,
    DesktopSearchDrop,
    ModalConfirmation,
    ModalEnquiry,
    OpenModalEnquiry,
    AccordionPart,
    BlockInterestedIn,
    BlockSlideIndexImg,
    BlockSlideIndexNoImg,
    BlockSlideIndexProfile,
    BlockSlideIndexImgSimple,
    SvgLogoRw,
    SvgLogoFull,
    SvgChevRight,
    SvgChevLeft,
    SvgChevRightCircled,
    SvgChevLightDown,
    SvgIconX,
    SvgClock,
    SvgPillarMedal,
    SvgPillarBriefcase,
    SvgPillarBook,
    SvgPillarPeople,
    SvgSocialSquareFb,
    SvgSocialSquareTw,
    SvgSocialSquareLinkedIn,
    SvgCoreValuesCenterFocus,
    SvgCoreValuesCashClock,
    SvgCoreValuesFlagCheckered,
    SvgCoreValuesChartBox,
    SvgCoreValuesCircleDot,
    SvgCoreValuesCheckDecagram,
    SvgIconFaqs,
    SvgIconPayments,
    VideoThumb,
    ResPicture,
    SearchBar,
    ResizeCaptionHover,
    SelectField,
    SelectFieldSml,
    ScrollTo,
    InterestedInNavigator,
    BlockContactVacancyApplication,
    ScrollDown,
    SocialLinks,
    AnimThreeToRect,
    AnimSingle,
  },
  mounted() {
    // Custom CMS buttons modal trigger
    document.addEventListener('click', e => {
      if (
        typeof e.target !== 'undefined'
        && e.target.tagName === 'A'
        && typeof e.target.href === 'string'
      ) {
        const match = /[https?:\\/\\/]?#(modal.*)/gm
        const result = match.exec(e.target.href)
        if (result && result.length > 1) {
          e.preventDefault()
          switch (result[1]) {
            case 'modal-phone':
              tab.value = 2
              break
            case 'modal-appointment':
              tab.value = 3
              break
            default:
              tab.value = 1
          }
        }
      }
    })

    document.addEventListener('onFormieInit', e => {

      const Formie = e.detail.formie
      if (typeof Formie.$forms !== 'undefined' && Formie.$forms.length) {
        const validFormHandles = ['readyToTalkEnquiry'];
        Formie.$forms.forEach(element => {
          if (typeof element === 'object' && 'form' in element) {
            if (typeof element.form === 'object' && 'config' in element.form) {
              if (typeof element.form.config === 'object' && 'formHandle' in element.form.config) {
                if (validFormHandles.includes(element.form.config.formHandle)) {
                  element.addEventListener('onAfterFormieSubmit', e => {
                    tab.value = 0;
                    showConfirmationModal.value = true
                  });
                }
              } else {
                console.error('onFormieInit (app.js) `element.form.config` is not an object with property `formHandle`')
              }
            } else {
              console.error('onFormieInit (app.js) `element.form` is not an object with property `config`')
            }
          } else {
            console.error('onFormieInit (app.js) `element` is not an object with property `form`')
          }
        })
      }
    })

    const mapContainerEl = document.querySelector('.google-map')
    if (mapContainerEl !== null) {
      const script = document.createElement('script');
      script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDsgDfKoTt7_1txfFqqokq5_gzYfdtOL8Y&callback=initMap';
      script.async = true;

      const latLng = { lat: -28.1000391, lng: 153.4226075 }
      const infoWindowContent = mapContainerEl.querySelector('.info-window').innerHTML

      window.initMap = function() {
        const googleMap = new google.maps.Map(mapContainerEl, {
          mapId: "91c343ebd4ec0f0a",
          center: latLng,
          zoom: 16,
        })
        const image = {
          url: "/dist/assets/images/logo/mapMarker.svg",
          size: new google.maps.Size(90, 60),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(0, 0),
        }
        const marker = new google.maps.Marker({
          position: latLng,
          map: googleMap,
          icon: image,
          title: 'Robbins Watson',
          zIndex: 50,
        });
        const infoWindow = new google.maps.InfoWindow({
          content: infoWindowContent,
          map: googleMap,
          anchor: marker,
        });
        marker.addListener('click', () => {
          infoWindow.open({
            map: googleMap,
            anchor: marker,
          })
        })
      }
      document.head.appendChild(script);
    }
  },
  config: {
    devtools: true,
  },
})
app.mount('#app')

import '../css/app.pcss'


