<template>
  <div
    ref="el"
    class="select-field"
    :class="{ '-open': isOpen }"
  >
    <input
      v-model="val.id"
      type="hidden"
      :name="fieldName"
    />
    <div
      class="-head text-gold inline-flex items-center gap-4"
      :class="labelClass"
      @click="toggle"
    >
      <span>{{ valString }}</span>
      <ChevLightDown />
    </div>
    <div class="-drop">
      <div>
        <div
          v-if="multi"
          class="-opt"
          @click="clearMulti"
        >
          <div
            class="-multi-opt"
            :class="{ '-selected': [...val].length === 0 }"
          >
            <span
              class="-tick"
            >
              <span />
            </span>
            <span class="-label">{{ allOption }}</span>
          </div>
        </div>
        <div
          v-for="option in options"
          :key="option.value"
          class="-opt"
          @click="select(option)"
        >
          <div
            v-if="multi"
            class="-multi-opt"
            :class="{ '-selected': val.has(option) }"
          >
            <span
              class="-tick"
            >
              <span />
            </span>
            <span class="-label">{{ option.label }}</span>
          </div>
          <div
            v-else
          >
            {{ option.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch, nextTick } from 'vue'
import ChevLightDown from '../svg/ChevLightDown.vue'
import useClickOff from '../../composables/useClickOff.js'

const clickOff = useClickOff()

const props = defineProps({
  fieldName: {
    type: String,
    required: true,
  },
  initVal: {
    type: [Number, String],
    default: 0,
  },
  labelClass: {
    type: String,
    default: '',
  },
  options: {
    type: Array,
    default: () => [],
  },
  allOption: {
    type: String,
    default: null,
  },
  multi: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['selected', 'open', 'close'])

let initVal = []
if (props.multi) {
  const ids = props.initVal.split(',')
  initVal = new Set(props.options.filter(opt => ids.includes(opt.id.toString())))
} else {
  initVal = props.options.find(opt => opt.id === props.initVal) ?? (props.options.length ? props.options[0] : { id: 0 })
}

const val = ref(initVal)
const isOpen = ref(false)
const el = ref(null)

if (val.value) {
  emit('selected', val.value)
}

watch(() => props.options, () => {
  nextTick(() => {
    val.value = props.options.length ? props.options[0] : { id: 0 }
    emit('selected', val.value)
  })
})

const valString = computed(() => {
  if (props.multi) {
    const options = [...val.value]
    if (options.length === 0) {
      return props.allOption
    } else {
      return options.map(opt => opt.label).join(', ')
    }
  } else {
    return val.value.label
  }
})

const select = (option) => {
  if (props.multi) {
    if (val.value.has(option)) {
      val.value.delete(option)
    } else {
      val.value.add(option)
    }
  } else {
    val.value = option
    isOpen.value = false
  }
  emit('selected', val.value)
}
const toggle = () => {
  isOpen.value = ! isOpen.value
  if (isOpen.value) {
    emit('open')
    clickOff.focus(el.value, () => {
      isOpen.value = false
    })
  } else {
    emit('close')
    clickOff.clear(el.value)
  }
}
const clearMulti = () => {
  val.value.clear()
}

</script>
