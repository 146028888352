<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2.5227em"
    height="2.4em"
    viewBox="0 0 25.227 24"
  >
    <path
      d="M26.227,13.534l-2.8-3.188.39-4.22-4.14-.94L17.512,1.54l-3.9,1.674L9.715,1.54,7.548,5.186l-4.14.929.39,4.22L1,13.534l2.8,3.188-.39,4.231,4.14.94L9.715,25.54l3.9-1.686,3.9,1.674,2.167-3.646,4.14-.94-.39-4.22,2.8-3.188M11.32,19.268,6.733,14.681,8.35,13.064l2.97,2.958,7.557-7.557,1.617,1.628Z"
      transform="translate(-1 -1.54)"
      fill="currentColor"
    />
  </svg>
</template>
