import { ref } from 'vue'

const current = ref(null)

const removeOverlay = () => {
  // current.value.close()
  current.value = null
}

const addOverlay = (modal) => {
  if (current.value !== null) {
    removeOverlay()
  }
  current.value = modal
}





export default function useOverlayGlobal() {
  return {
    current,
    addOverlay,
    removeOverlay,
  }
}
