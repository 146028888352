<template>
  <div
    ref="dropContainerMobile"
    class="-drop-mobile"
  >
    <div
      ref="dropHeightMobileUpper"
      class="center-frame"
    >
      <MobileCtaAndSearch />
    </div>
    <div
      class="-levels"
      :class="{ '-hidden': searchMobileActive }"
    >
      <div
        class="-strip"
        :style="`left:${(mobileMenuLevel - 1) * -100}%`"
      >
        <TopNavDropMobileLevel
          :nodes="nodes"
          :i="1"
        />
        <TopNavDropMobileLevel
          v-if="mobileMenuLevel2 !== null"
          :nodes="mobileMenuLevel2"
          :i="2"
        />
        <TopNavDropMobileLevel
          v-if="mobileMenuLevel3 !== null"
          :nodes="mobileMenuLevel3"
          :i="3"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted } from 'vue'
import MobileCtaAndSearch from './widgets/MobileCtaAndSearch.vue'
import TopNavDropMobileLevel from './TopNavDropMobileLevel.vue'
import useTopNavDrop from '#/useTopNavDrop.js'

const props = defineProps({
  nodes: {
    type: Array,
    required: true,
  },
})


const {
  dropContainerMobile,
  dropHeightMobileUpper,
  dropTransitionEndMobile,
  mobileMenuLevel,
  mobileMenuLevel2,
  mobileMenuLevel3,
  searchMobileActive,
} = useTopNavDrop()

onMounted(() => {
  setTimeout(() => {
    if (typeof dropContainer === 'undefined') {
      return
    }
    dropContainer?.value.addEventListener('transitionend', dropTransitionEndMobile)
  }, 100)
})

</script>
