<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2.2em"
    height="2.2em"
    viewBox="0 1 28 28"
  >
    <path
      d="M24.5,27H3.9c-1.4,0-2.6-1.2-2.6-2.6V3.9c0-1.4,1.2-2.6,2.6-2.6h20.6c1.4,0,2.6,1.2,2.6,2.6v20.6
	C27,25.9,25.9,27,24.5,27z M5.2,19.6L5.2,19.6c1.7,1,3.7,1.6,5.7,1.6c5.7,0.1,10.4-4.3,10.5-10c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0-0.5
	c0.7-0.5,1.3-1.1,1.8-1.8c-0.7,0.3-1.4,0.5-2.1,0.6c0.8-0.4,1.3-1.1,1.6-2c-0.7,0.4-1.5,0.7-2.3,0.9c-0.7-0.7-1.7-1.1-2.7-1.1
	c-2,0-3.7,1.6-3.7,3.6c0,0.3,0,0.5,0.1,0.8c-2.9-0.1-5.7-1.5-7.6-3.7C6.1,8.3,5.9,8.9,5.9,9.6c0,1.2,0.6,2.3,1.6,3
	c-0.6,0-1.2-0.2-1.7-0.4v0c0,1.7,1.3,3.2,3,3.5c-0.5,0.1-1.1,0.2-1.7,0.1c0.5,1.5,1.9,2.5,3.4,2.5c-1.3,1-2.9,1.5-4.6,1.5
	C5.8,19.7,5.5,19.7,5.2,19.6z"
      fill="currentColor"
    />
  </svg>
</template>
