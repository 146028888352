<template>
  <div>
    <h4 class="font-bold">
      Legal made simple, start here.
    </h4>
    <p class="text-15 mb-6">
      Navigate legal matters effortlessly. Our user-friendly online system provides clarity on steps, roles, and expert guidance. Streamline the process, saving both time and costs.
    </p>
    <h5 class="h5-lg mb-1">
      Get started online today with
    </h5>
    <div>
      <select-field
        class="h5-lg inline-block"
        field-name="settifyOptions"
        :init-val="options[0].id"
        :options="options"
        @selected="selectSettifyOpt"
        @open="openSettifyOpts"
      />
    </div>
    <a
      class="button -med mt-8"
      target="_blank"
      rel="noreferrer noopenner"
      :href="url(val)"
    >
      Start Now
    </a>
    <div class="-spacer" />
  </div>
</template>
<script setup>
import { ref } from 'vue'
import SelectField from '~/widgets/SelectField.vue';
import useBeginOnline from '#/useBeginOnline.js'

const emit = defineEmits(['opensettifyopts'])
const { options, url } = useBeginOnline()

const val = ref(options[0].id);

const selectSettifyOpt = (v) => {
  val.value = v.id
}
const openSettifyOpts = () => {
  emit('opensettifyopts')
}

</script>
