<template>
  <div class="resizing-caption">
    <div
      class="bg-white"
      :style="`height:${h}px;width:${w}px`"
    />
    <div
      ref="titleSized"
      class="-title"
    >
      <h4
        :class="{ 'text-gold': abstractIsShowing }"
      >
        {{ title }}
      </h4>
    </div>
    <div
      ref="abstractSized"
      class="-abstract"
    >
      <p
        v-if="abstract"
        class="-text"
        :class="{ '-show': show1 }"
      >
        {{ abstract }}
      </p>
      <p
        :class="{ '-show': show2 }"
      >
        <a
          :href="cta.href"
          class="text-gold med-caps py-4 inline-flex gap-3 chev-hover-right"
        >
          <span>{{ cta.text }}</span>
          <ChevRight
            class="text-20"
          />
        </a>
      </p>
    </div>
  </div>
</template>
<script setup>
import { onMounted } from 'vue'
import ChevRight from '../svg/ChevRight.vue'

const props = defineProps({
  state: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  abstract: {
    type: String,
    default: null,
  },
  cta: {
    type: Object,
    required: true,
  },
})

// eslint-disable-next-line vue/no-setup-props-destructure
const { h, w, show1, show2, titleSized, abstractSized, abstractIsShowing } = props.state

onMounted(() => {
  /*
  I think this will fix the caption not being white behind text
  I can't replicate on local, even with throttling so I'm also
  putting the logging in here to see if this is where it might fail
  will come back and clean up once I know more
  todo
  */
  setTimeout(() => {
    if (props.state.mountedCallback !== null) {
      props.state.mountedCallback()
    }
  }, 1000)
  if (props.state.mountedCallback === null) {
    console.log('mountedCallback === null - good call')
  }
  setTimeout(() => {
    if (props.state.mountedCallback === null) {
      console.log('mountedCallback === null - good call')
    }
  }, 400)
})


</script>
