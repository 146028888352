<template>
  <svg
    id="book-heart"
    xmlns="http://www.w3.org/2000/svg"
    width="2.1409em"
    height="2.4em"
    viewBox="0 0 21.409 24"
  >
    <path
      id="book-heart-2"
      data-name="book-heart"
      d="M20.9,26l-.676-.563c-2.254-2.141-3.831-3.493-3.831-5.183a2.5,2.5,0,0,1,2.479-2.479,2.639,2.639,0,0,1,2.028.9,2.639,2.639,0,0,1,2.028-.9,2.43,2.43,0,0,1,2.479,2.479c0,1.69-1.577,3.042-3.831,5.183L20.9,26M6.254,24.535A2.253,2.253,0,0,1,4,22.282V4.254A2.246,2.246,0,0,1,6.254,2H7.38V9.887L10.2,8.2l2.817,1.69V2h6.761a2.253,2.253,0,0,1,2.254,2.254V14.485l-1.127-.09a6.766,6.766,0,0,0-5.859,10.141Z"
      transform="translate(-4 -2)"
      fill="currentColor"
    />
  </svg>
</template>
