<template>
  <div>
    <h5 class="font-bold">
      Let us help you today
    </h5>
    <p>
      Let us discuss your case and identify how we can work with you to achieve the best possible outcome.
    </p>
    <a
      href="https://outlook.office365.com/owa/calendar/RobbinsWatsonSolicitors@robbinswatson.com.au/bookings/"
      class="button -med mt-3"
      target="_blank"
    >
      Schedule an appointment
    </a>
  </div>
</template>
