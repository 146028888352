<template>
  <div
    class="select-field-simple"
    :class="{ '-empty': currentVal === '' }"
  >
    <select
      v-model="currentVal"
      :name="fieldName"
    >
      <option
        value=""
        disabled
      >
        {{ emptyOption }}
      </option>
      <option
        v-for="opt in options"
        :key="opt.value"
        :value="opt.value"
      >
        {{ opt.text }}
      </option>
    </select>
    <div class="-chev">
      <ChevLightDown class="text-19" />
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import ChevLightDown from '../svg/ChevLightDown.vue'

const props = defineProps({
  fieldName: {
    type: String,
    required: true,
  },
  opts: {
    type: Array,
    default: null,
  },
  optsAsObj: {
    type: Object,
    default: null,
  },
  emptyOption: {
    type: String,
    default: 'Please Select...',
  },
  initVal: {
    type: String,
    default: '',
  },
})

const options = ref([])
const currentVal = ref(props.initVal)

if (props.opts !== null) {
  props.opts.forEach(el => {
    options.value.push({
      value: el,
      text: el,
    })
  })
} else if (props.optsAsObj !== null) {
  for (const pName in props.optsAsObj) {
    options.value.push({
      value: pName,
      text: props.optsAsObj[pName],
    })
  }
} else {
  console.error('SelectFieldSml.vue needs options')
}

</script>
