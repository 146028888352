<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.4em"
    height="1.4em"
    viewBox="0 0 14 14"
  >
    <path
      d="M13.062,14h0L9.213,10.151a5.629,5.629,0,0,1-3.526,1.224,5.687,5.687,0,1,1,5.687-5.687,5.628,5.628,0,0,1-1.224,3.526L14,13.062,13.063,14ZM5.7,1.326A4.375,4.375,0,1,0,10.076,5.7,4.379,4.379,0,0,0,5.7,1.326Z"
      fill="currentColor"
    />
  </svg>
</template>
