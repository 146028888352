<template>
  <label
    class="-upload"
    :class="{ '-has-file': fileName }"
  >
    <p v-if="label">{{ label }}</p>
    <div class="-field">
      <div class="-native">
        <input
          type="file"
          :name="fieldName"
          :accept="accept"
          @change="fileChange"
        />
      </div>
      <div class="-overlay">
        <div class="button -choose-file">CHOOSE FILE</div>
        <p v-if="fileName">{{ fileName }}</p>
        <p v-else>No file chosen</p>
      </div>
    </div>
  </label>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
  label: {
    type: String,
    default: null,
  },
  fieldName: {
    type: String,
    required: true,
  },
  accept: {
    type: String,
    default: null,
  },
})

const fileName = ref(null)

const fileChange = (e) => {
  console.log('fileChange', e, e.target, e.target.files, e.target.files[0].name)
  const files = e.target.files
  if (files instanceof FileList) {
    if (files.length > 1) {
      console.error('todo - control multiple uploads')
    } else if (files.length === 1) {
      const file = files[0]
      fileName.value = file.name
    } else {
      fileName.value = null
    }
  } else {
    console.error('input files is not an array')
  }
}

</script>
