<template>
  <div
    class="accordion-part"
    :class="{ '-open': isOpen }"
  >
    <div
      class="-head"
      @click="toggle"
    >
      <slot name="head"></slot>
      <SvgChevLightDown />
    </div>
    <div
      class="-article"
      :style="`height:${height}px`"
    >
      <article
        ref="articleEl"
      >
        <slot name="article"></slot>
      </article>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import SvgChevLightDown from '~/svg/ChevLightDown.vue'

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
})

const isOpen = ref(props.isOpen)
const articleEl = ref(null)
const height = ref(0)

const updateHeight = () => {
  if (isOpen.value) {
    height.value = articleEl.value.offsetHeight
  } else {
    height.value = 0
  }
}

const toggle = () => {
  isOpen.value = ! isOpen.value
  updateHeight()
}

onMounted(() => {
  updateHeight()
})

</script>
