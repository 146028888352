<template>
  <section class="section block-slide-index -no-img">
    <div class="page-gutter-left">
      <header>
        <h3
          v-if="title"
          class="max-w-[50vw]"
        >
          {{ title }}
        </h3>
        <nav>
          <span
            class="-prev"
            :class="{ '-disabled': currentI === 0 }"
            @click="state.prev"
          >
            <ChevDown />
          </span>
          <span
            class="-next"
            :class="{ '-disabled': currentI === slides.length - 1 }"
            @click="state.next"
          >
            <ChevDown />
          </span>
        </nav>
      </header>
      <div
        ref="scrollWindow"
        class="-scroll-window hide-scrollbar"
      >
        <div
          class="-sliding-strip"
        >
          <BlockSlideIndexNoImgSlide
            v-for="(slide, i) in slides"
            :key="i"
            :slide="slide"
            :state="state"
            :i="i"
          />
        </div>
      </div>
      <SlideIndexScrollbar
        :state="state"
      />
    </div>
  </section>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import BlockSlideIndexNoImgSlide from './BlockSlideIndexNoImgSlide.vue'
import SlideIndex from '../classes/SlideIndex.js'
import ChevDown from './svg/ChevDown.vue'
import SlideIndexScrollbar from '~/widgets/SlideIndexScrollbar.vue'

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  slides: {
    type: Array,
    required: true,
  },
})

const scrollWindow = ref(null)

const state = new SlideIndex(props.slides, scrollWindow)
const { currentI } = state

onMounted(() => {
  state.mounted()
  scrollWindow.value.addEventListener('scroll', () => {
    state.handleManualScroll()
  });
})


</script>
