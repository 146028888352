import { ref, nextTick, reactive } from 'vue'
import useClickOff from './useClickOff.js'
import useOverlayGlobal from './useOverlayGlobal.js'
import useBeginOnline from '#/useBeginOnline.js'

const clickOff = useClickOff()
const { addOverlay, removeOverlay } = useOverlayGlobal()

const dropIsOpen = ref(false)
const dropIsOpenMobile = ref(false)
const dropIsOpenDelay = ref(false)

const items = ref([])
let topNavEl = null

const tabCascadeHide = ref([])
const linkCascadeHide = ref([])

const dropContainer = ref(null)
const dropContainerMobile = ref(null)
const dropHeight = ref(null)
const dropHeightMobileUpper = ref(null)
const dropHeightMobileLower = reactive({
  1: null,
  2: null,
  3: null,
})
const currentTab = ref(0)
const currentTabFast = ref(currentTab.value)
const currentTabMobile = ref(null)

const solidBg = ref(false)
const visible = ref(true)

const desktopSearchDropShowing = ref(false)

const desktopSearchResultsHeight = ref(null)
const mobileSearchResultsHeight = ref(null)
const topNavTop = ref(null)
const mobileNavMaxHeight = ref('none')

const searchTerm = ref('')
const searchResults = ref([])
const searchResultLoading = ref(false)
const searchApiResponded = ref(false)

const desktopSearchExpander = ref(null)

const mobileSubNavContainers = ref([])
const mobileSubNavHeights = ref([])

const searchMobileActive = ref(false)

const mobileMenuLevel = ref(1)

const mobileMenuLevel2 = ref([])
const mobileMenuLevel3 = ref([])

let tabCascadeTimeouts = []
const startTabCascade = (hide = false) => {
  tabCascadeTimeouts.forEach(timeout => clearTimeout(timeout))
  tabCascadeTimeouts = []
  for (let i = 0; i < tabCascadeHide.value.length; i++) {
    if (hide) {
      tabCascadeHide.value[i] = true
    } else {
      tabCascadeTimeouts?.push(setTimeout(() => {
        tabCascadeHide.value[i] = false
      }, i * 140))
    }
  }
}
const startLinkCascade = (hide = false) => {
  for (let i = 0; i < linkCascadeHide.value.length; i++) {
    if (hide) {
      linkCascadeHide.value[i] = true
    } else {
      setTimeout(() => {
        linkCascadeHide.value[i] = false
      }, i * 30)
    }
  }
}

/**
 * @param height String '0' | 'auto' | 'full' | 'search' | 'search-results-mobile'
 */
const _setContainerHeight = (height, screen) => {
  let h = 0
  const dropCon = screen === 'desktop'
    ? dropContainer
    : dropContainerMobile
  if (dropCon.value.style.height === 'auto') {
    if (height === '0') {
      setTimeout(() => {
        _setContainerHeight('0', screen)
      }, 100)
    } else {
      setTimeout(() => {
        _setContainerHeight('search', screen)
      }, 100)
    }
    height = 'full'
  }
  let deferred = false
  switch (height) {
    case '0':
      searchResults.value = []
      h = 0
      break
    case 'full':
      searchResults.value = []
      if (screen === 'desktop') {
        h = dropHeight.value.offsetHeight + 'px'
      } else {
        
        const topH = topNavTop.value.offsetHeight
        const searchH = dropHeightMobileUpper.value.offsetHeight
        // console.log('topH', topH, window.innerHeight, searchH)
        const d = mobileMenuLevel.value > 1 ? 80 : 12
        mobileNavMaxHeight.value = window.innerHeight - searchH - topH - d + 'px'
        
        setTimeout(() => {
          h = searchH + dropHeightMobileLower[mobileMenuLevel.value].offsetHeight + 'px'
          dropCon.value.style.height = h
        }, 200)
        deferred = true
      }
      break
    case 'search':
      searchResults.value = []
      h = '146px'
      break
    case 'search-results-mobile':
      h = mobileSearchResultsHeight.value.offsetHeight + 166 + 'px'
      break
    case 'auto':
      searchResults.value = []
      h = 'auto'
      break
    default:
      console.error('_setContainerHeight() invalid param', height)
  }
  if ( ! deferred) {
    dropCon.value.style.height = h
  }
}

const setHeightOfDrop = (screen = 'desktop') => {
  const isOpen = screen === 'desktop'
    ? dropIsOpen
    : dropIsOpenMobile
  if (isOpen.value) {
    _setContainerHeight('full', screen)
    dropIsOpenDelay.value = true
    clickOff.focus(topNavEl.value, () => {
      isOpen.value = false
      setHeightOfDrop(screen)
    })
    addOverlay(true)
    if (screen === 'desktop') {
      startTabCascade()
    }
  } else {
    clickOff.clear(topNavEl.value)
    removeOverlay()
    _setContainerHeight('0', screen)
    if (screen === 'desktop') {
      startLinkCascade(true)
      startTabCascade(true)
    }
    setTimeout(() => {
      dropIsOpenDelay.value = false
      searchMobileActive.value = false
    }, 100)
  }
}

const toggleDrop = () => {
  dropIsOpen.value = !dropIsOpen.value
  setHeightOfDrop()
}
const toggleDropMobile = () => {
  dropIsOpenMobile.value = ! dropIsOpenMobile.value
  setHeightOfDrop('mobile')
}

const dropTransitionEnd = (e) => {
  if (e.target !== dropContainer.value) {
    return false
  }
  if (dropIsOpen.value && searchMobileActive.value === false) {
    _setContainerHeight('auto')
    startLinkCascade()
  } else {
    // setCurrentMobileTab(null)
  }
}
const dropTransitionEndMobile = (e) => {
  if (e.target !== dropContainerMobile.value) {
    return false
  }
  if (dropIsOpen.value && searchMobileActive.value === false) {
    _setContainerHeight('auto', 'mobile')
    startLinkCascade()
  } else {
    // setCurrentMobileTab(null)
  }
}

const { beginOnlineNodes } = useBeginOnline()

const mobileDrilldown = (from, node) => {
  mobileMenuLevel.value = from + 1
  const menu = (mobileMenuLevel.value === 2)
    ? mobileMenuLevel2
    : mobileMenuLevel3
  menu.value = node.children
  setHeightOfDrop('mobile')
}
const mobileDrillBack = () => {
  mobileMenuLevel.value -= 1
  setHeightOfDrop('mobile')
}
const mobileDrilldownBeginOnline = (from, node) => {
  mobileMenuLevel.value = from + 1
  const menu = (mobileMenuLevel.value === 2)
    ? mobileMenuLevel2
    : mobileMenuLevel3
  menu.value = beginOnlineNodes()
  setHeightOfDrop('mobile')
}


const setCurrentTab = (i, startCascade = true) => {
  currentTab.value = i
  nextTick(() => {
    linkCascadeHide.value = items.value[i]?.children?.map(j => true)
    if (startCascade) {
      startLinkCascade()
    }
  })
}


const changeCurrentTab = (i) => {
  if (currentTab.value !== i) {
    linkCascadeHide.value = items.value[currentTab.value]?.children?.map(
      j => true)
    currentTabFast.value = i
    setTimeout(() => {
      setCurrentTab(i)
    }, 400)
  }
}

const hasDropLg = (node, level) => {
  return 'children' in node
    && (
      (level > 1 && node.children.length > 0)
      || node.children.length > 1 /* only 'Our Expertise' for now */
    )
}
const hasDropBeginOnline = (node) => {
  return node.type === 'verbb\\navigation\\nodetypes\\PassiveType'
}
const hasDropAny = (node, level) => {
  return hasDropBeginOnline(node) || hasDropLg(node, level)
}


const toggleSearchMobile = () => {
  searchMobileActive.value = !searchMobileActive.value
  if (searchMobileActive.value) {
    startTabCascade(true)
    if (searchResults.value.length) {
      _setContainerHeight('search-results-mobile', 'mobile')
    } else {
      _setContainerHeight('search', 'mobile')
    }
    // setCurrentMobileTab(null)
    if (searchTerm.value.length > 2) {
      setTimeout(() => {
        searchQuery(searchTerm.value, 'mobile')
      }, 100)
    }
  } else {
    _setContainerHeight('full', 'mobile')
    startTabCascade()
  }
}

const initTopNavDrop = (initItems, el) => {
  tabCascadeHide.value = initItems?.map(i => true)
  tabCascadeHide.value?.push(true) /* bottom <hr> */
  topNavEl = el
  items.value = initItems
  setCurrentTab(currentTab.value, false)
}

const showDesktopSearchDrop = () => {
  desktopSearchDropShowing.value = true
  if (dropIsOpen.value) {
    dropIsOpen.value = false
    setHeightOfDrop()
  }
}

const searchQuery = (term, desktopOrMobile = 'desktop') => {
  searchResultLoading.value = true
  searchApiResponded.value = false
  axios.get('/api/search', {
    params: {
      q: term,
    },
  }).then(response => {
    searchResultLoading.value = false
    searchApiResponded.value = true
    if (Array.isArray(response.data)) {
      searchResults.value = response.data
    } else {
      console.error('response from /api/search', response)
    }
    // searchResults.value = [{ "title":"Wills","href":"https:\/\/robwat.ddev.site\/expertise\/wills-and-estates\/wills" },{ "title":"Contested Wills & Estate","href":"https:\/\/robwat.ddev.site\/expertise\/wills-and-estates\/contested-wills-estate" },{ "title":"Will & Estate Disputes","href":"https:\/\/robwat.ddev.site\/expertise\/dispute-resolution-litigation\/will-estate-disputes" },{ "title":"Fixed Fee Wills and Powers of Attorney","href":"https:\/\/robwat.ddev.site\/fixed-fee-will-power-of-attorney" },{ "title":"Wills & Estates","href":"https:\/\/robwat.ddev.site\/expertise\/wills-and-estates" }]
  }).catch(() => {
    searchResultLoading.value = false
    searchResults.value = []
  }).then(() => {
    nextTick(() => {
      if (desktopOrMobile === 'mobile') {
        _setContainerHeight('search-results-mobile', 'mobile')
      } else {
        desktopSearchExpander.value.style.height = 90 + desktopSearchResultsHeight.value.offsetHeight + 'px'
      }
    })
  })

}

let mobileSearchKeyTimeout = null
const mobileSearchKeydown = (e) => {
  clearTimeout(mobileSearchKeyTimeout)
  mobileSearchKeyTimeout = setTimeout(() => {
    mobileSearchKeyTimeout = null
    if (searchTerm.value.length < 3) {
      searchApiResponded.value = false
      if (searchResults.value.length) {
        _setContainerHeight('search', 'mobile')
      }
    } else {
      searchQuery(searchTerm.value, 'mobile')
    }
  }, 1000)
}

let desktopSearchKeyTimeout = null
const desktopSearchKeydown = (e) => {
  clearTimeout(desktopSearchKeyTimeout)
  desktopSearchKeyTimeout = setTimeout(() => {
    desktopSearchKeyTimeout = null
    if (searchTerm.value.length < 3) {
      searchResults.value = []
      desktopSearchExpander.value.style.height = '40px'
      searchApiResponded.value = false
    } else {
      searchQuery(searchTerm.value, 'search-results-desktop')
    }
  }, 1000)
}

export default function useTopNavDrop() {
  return {
    dropIsOpen,
    dropIsOpenMobile,
    dropIsOpenDelay,
    items,
    initTopNavDrop,
    toggleDrop,
    toggleDropMobile,
    tabCascadeHide,

    dropContainer,
    dropContainerMobile,
    dropHeight,
    dropHeightMobileUpper,
    dropHeightMobileLower,

    currentTab,
    currentTabFast,
    currentTabMobile,
    linkCascadeHide,
    changeCurrentTab,
    solidBg,
    visible,

    mobileSubNavContainers,
    mobileSubNavHeights,

    searchMobileActive,
    toggleSearchMobile,

    dropTransitionEnd,
    dropTransitionEndMobile,

    desktopSearchDropShowing,
    showDesktopSearchDrop,

    mobileNavMaxHeight,
    desktopSearchResultsHeight,
    mobileSearchResultsHeight,
    topNavTop,

    searchTerm,
    searchResultLoading,
    searchResults,
    searchApiResponded,
    desktopSearchKeydown,
    mobileSearchKeydown,
    desktopSearchExpander,
    
    mobileMenuLevel,
    mobileMenuLevel2,
    mobileMenuLevel3,
    mobileDrilldown,
    mobileDrillBack,
    mobileDrilldownBeginOnline,

    hasDropLg,
    hasDropBeginOnline,
    hasDropAny,
    
    beginOnlineNodes,
  }
}
