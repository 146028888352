<template>
  <header
    ref="el"
    class="top-nav"
    :class="{
      'not-sticky': !isSticky,
      '-solid-bg': solidBg || dropIsOpenDelay,
      '-visible': visible || dropIsOpenDelay,
      '-drop-is-open': dropIsOpenDelay,
      '-drop-is-open-early': dropIsOpen || dropIsOpenMobile,
    }"
  >
    <div
      ref="topNavTop"
      class="-top center-frame"
    >
      <a
        href="/"
        class="-logo"
      >
        <LogoFull />
      </a>
      <MenuHamburger
        class="text-10"
        @click="toggleDropMobile"
      />
      <nav class="-nav">
        <template v-for="(node, index) in nodes">
          <a
            v-if="hasDropLg(node)"
            :key="index"
            class="inline-flex gap-1 items-center cursor-pointer"
            @click="toggleDrop"
          >
            <span>{{ node.title }}</span>
            <span class="-chev">
              <SvgChevDown />
            </span>
          </a>
          <div
            v-else-if="hasDropBeginOnline(node)"
            :key="index + 1000"
            class="-begin-online"
          >
            <span>{{ node.title }}</span>
            <div class="-drop-sml">
              <div>
                <div class="-height">
                  <a
                    v-for="(link, j) in beginOnlineNodes()"
                    :key="j"
                    :href="link.url"
                    target="_blank"
                    rel="noreferrer noopener"
                  >{{ link.title }}</a>
                </div>
              </div>
            </div>
          </div>
          <a
            v-else
            :key="0 - index"
            :href="node.url"
          >
            {{ node.title }}
          </a>
        </template>
        <a
          v-if="nodes.length"
          class="cursor-pointer"
          @click.prevent="showDesktopSearchDrop"
        >
          <MagnifyGlass
            class="text-10"
          />
        </a>
        <a
          class="button -sml ml-6 cursor-pointer"
          @click.prevent="openModal(1)"
        >Enquire</a>
      </nav>
    </div>
    <TopNavDrop
      v-if="nodes.length"
      :nodes="nodes[0]?.children || []"
    />
    <TopNavDropMobile
      v-if="nodes.length"
      :nodes="nodes || []"
    />
  </header>
</template>
<script setup>
import MagnifyGlass from '~/svg/MagnifyGlass.vue'
import LogoFull from '~/svg/LogoFull.vue'
import { onMounted, ref } from 'vue'
import SvgChevDown from '~/svg/ChevDown.vue'
import useTopNavDrop from '#/useTopNavDrop.js'
import TopNavDrop from '~/TopNavDrop.vue'
import TopNavDropMobile from '~/TopNavDropMobile.vue'
import useModalEnquiry from '#/useModalEnquiry.js'
import MenuHamburger from '~/widgets/MenuHamburger.vue'

const {
  dropIsOpen,
  dropIsOpenMobile,
  dropIsOpenDelay,
  solidBg,
  visible,
  initTopNavDrop,
  toggleDrop,
  toggleDropMobile,
  showDesktopSearchDrop,
  topNavTop,
  hasDropBeginOnline,
  hasDropLg,
  beginOnlineNodes,
} = useTopNavDrop()

const props = defineProps({
  nodes: {
    type: Object,
    required: true,
  },
  isSticky: {
    type: Boolean,
    default: true,
  },
})

const el = ref(null)

initTopNavDrop(props.nodes[0]?.children, el)


const lastY = ref(0)

const { tab } = useModalEnquiry()
const openModal = (i) => {
  tab.value = i
}

onMounted(() => {
  if (!props.isSticky) {
    return
  }
  lastY.value = window.scrollY
  if (lastY.value >= 15) {
    solidBg.value = true
  }
  window.addEventListener('scroll', () => {
    const sY = window.scrollY
    if (dropIsOpen.value) {
      lastY.value = sY
      return false
    }
    if (sY < 15) {
      solidBg.value = false
      visible.value = true
    } else {
      if (sY > lastY.value + 5) {
        visible.value = false
      } else if (sY < lastY.value - 5) {
        solidBg.value = true
        visible.value = true
      }
    }
    lastY.value = sY
  })
})



</script>
