<template>
  <div
    class="search-top shadow-nav bg-overlay"
    :class="{ '-showing': desktopSearchDropShowing, '-has-results': searchResults.length }"
    @click="desktopSearchDropShowing = false"
  >
    <div
      class="-window"
      @click.stop
    >
      <div class="-content">
        <div
          class="-close"
          @click="desktopSearchDropShowing = false"
        >
          <IconLgX
            class="text-10 text-greyMed"
          />
        </div>
        <div class="center-frame">
          <div
            ref="desktopSearchExpander"
            class="-expander"
          >
            <div class="-term flex gap-4 w-full">
              <input
                v-model="searchTerm"
                type="text"
                class="h3 border-0 border-b border-greyMed pb-2 w-full shrink inline-block"
                placeholder="Search our people, expertise or insights"
                @keydown="desktopSearchKeydown"
              />
              <button
                type="button"
                class="button -med"
              >
                <MagnifyGlass />
                <span>Search</span>
              </button>
            </div>
            <div
              v-if="searchResultLoading"
              class="loader-dots"
            >
              <LoaderDots />
            </div>
            <div
              class="-search-results"
              :class="{'-no-results': hasSearched && !searchResults.length }"
            >
              <div
                ref="desktopSearchResultsHeight"
              >
                <template v-if="hasSearched && searchResults.length">
                  <a
                    v-for="(result, i) in searchResults"
                    :key="i"
                    :href="result.href"
                  >
                    <span class="-chev">
                      <ChevRight />
                    </span>
                    <span
                      class="-title"
                      v-html="htmlTitle(result.title)"
                    ></span>
                  </a>
                </template>
                <template v-else-if="hasSearched && searchTerm !== '' && searchTerm.length < 3">
                  <span
                    class="-title -no-results"
                  >
                    Please enter at least three (3) characters to search.
                  </span>
                </template>
                <template v-else-if="searchTerm !== '' && ! searchResultLoading">
                  <span
                    class="-title -no-results"
                  >
                    There were no results for your search term.
                  </span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import MagnifyGlass from '../svg/MagnifyGlass.vue';
import IconLgX from '../svg/IconLgX.vue';
import ChevRight from '../svg/ChevRight.vue';
import useTopNavDrop from '../../composables/useTopNavDrop.js';
import { watch } from 'vue';
import LoaderDots from '~/svg/LoaderDots.vue'

const {
  desktopSearchDropShowing,
  desktopSearchResultsHeight,
  searchTerm,
  searchResults,
  desktopSearchKeydown,
  desktopSearchExpander,
  searchResultLoading,
} = useTopNavDrop();

const htmlTitle = title => {
  if (title.toLowerCase().includes(searchTerm.value.toLowerCase())) {
    const rg = new RegExp('(' + searchTerm.value.toLowerCase() + ')', 'ig');
    return title.replaceAll(rg, `<span class="text-gold">$1</span>`);
  }
  return title;
};

let hasSearched = false;
watch(searchResults, () => hasSearched = true);

</script>
