

const els = []

let timeout = null

const _setTimeout = () => {
  timeout = setTimeout(() => {
    clearTimeout(timeout)
    timeout = null
  }, 100)
}

const checkInView = (viewPortTop, viewPortBottom) => {
  els.forEach((el) => {
    if (el.finished === false) {
      if (el.centerY > viewPortTop && el.centerY < viewPortBottom) {
        el.callback()
        el.finished = true
      }
    }
  })
}

const handleScroll = () => {
  const viewPortTop = window.scrollY
  const viewPortBottom = viewPortTop + window.innerHeight;
  if (timeout === null) {
    els.forEach((el) => {
      el.calcCenterY(viewPortTop)
      // console.log('%c centerY ', 'background-color: #ffa', el.centerY)
    })
    _setTimeout()
  } else {
    clearTimeout(timeout)
    _setTimeout()
  }
  checkInView(viewPortTop, viewPortBottom)
}

window.addEventListener('scroll', handleScroll)

class El {
  constructor(callback) {
    this.ref = null
    this._el = null
    this.callback = callback
    this.finished = false
    this.centerY = null
    setTimeout(() => {
      // just in case the browser doesn't call 'scroll' event on page load
      handleScroll()
    }, 1000)
  }
  el() {
    if (this._el === null) {
      return this.ref.value // maybe don't memoise in case of vue re-render
    }
    return this._el
  }
  calcCenterY(viewPortTop) {
    const { top, height } = this.el().getBoundingClientRect()
    // console.log('%c top, height, viewPortTop ', 'background-color:#338;color:#fff')
    // console.log(top, height, viewPortTop)
    this.centerY = height / 2 + top + viewPortTop
  }
}

const inView = (ref, callback) => {
  const el = new El(callback)
  el.ref = ref
  els.push(el)
}
const inViewJs = (domEl, callback) => {
  const el = new El(callback)
  el._el = domEl
  els.push(el)
}


export default function useInView() {
  return {
    inView,
    inViewJs,
  }
}
