<template>
  <div
    ref="slideEl"
    class="-slide"
    :class="{ '-is-focused': isFocused }"
  >
    <div class="-uniform-width">
      <IconDoubleQuoteOpen
        class="text-greyLight text-10 mb-6"
      />
      <p class="h5 mb-6">
        {{ slide.content }}
      </p>
      <p class="sml-caps text-greyMed">
        {{ slide.attribution }}
      </p>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted } from 'vue'
import IconDoubleQuoteOpen from './svg/IconDoubleQuoteOpen.vue';

const props = defineProps({
  slide: {
    type: Object,
    required: true,
  },
  state: {
    type: Object,
    required: true,
  },
  i: {
    type: Number,
    required: true,
  },
})

const isFocused = ref(null)

const slideEl = ref(null)

const checkIfShouldShow = (i) => {
  if (i === props.i) {
    if (isFocused.value !== true) {
      if (isFocused.value === false) {
        props.state.moveTo(slideEl.value)
      }
      isFocused.value = true
    }
  } else if (isFocused.value !== false) {
    isFocused.value = false
  }
}

watch(props.state.currentI, (newI) => {
  checkIfShouldShow(newI)
})


onMounted(() => {
  checkIfShouldShow(props.state.currentI.value)
  props.state.registerSlideEl(props.i, slideEl)
})

</script>
