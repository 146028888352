import { ref } from 'vue'

export default class {
  constructor(slides, scrollWindow) {
    this.slides = slides
    this.scrollWindow = scrollWindow
    this.currentI = ref(0)
    this.transitionEndCallback = null
    this.isScrollAuto = false
    this.isScrollManual = false
    this.snapTimeout = null
    this.timeoutHandleManual = null
    this.resizeTimeout = null
    this.lastUniformWidthEl = null
    this.currentAnimationRun = null
    this.scrollbarPos = ref(0)
    this.scrollbarOrigin = 0
    this.scrollBarTrackEl = null
    this.scrollBarTrackRange = null
    this.scrollBarTrackWidth = null
    this.scrollBarHandleEl = null
    this.wheelIsMoving = false
    this.mouseIsDown = false
    document.addEventListener('mouseup', e => {
      this.mouseIsDown = false
      if (this.callOnMouseUp !== null) {
        this.callOnMouseUp()
        this.callOnMouseUp = null
      }
    })
    document.addEventListener('mousedown', e => {
      this.mouseIsDown = true
      if (this.currentAnimationRun !== null) {
        this.currentAnimationRun.interrupt()
        this.currentAnimationRun = null
        this.isScrollAuto = false
      }
    })
    this.touchIsDown = false
    document.addEventListener('touchend', e => {
      this.touchIsDown = false
      if (this.callOnTouchUp !== null) {
        this.callOnTouchUp()
        this.callOnTouchUp = null
      }
    })
    document.addEventListener('touchstart', e => {
      this.touchIsDown = true
      if (this.currentAnimationRun !== null) {
        this.currentAnimationRun.interrupt()
        this.currentAnimationRun = null
        this.isScrollAuto = false
      }
    })
    this.callOnMouseUp = null
    this.callOnTouchUp = null
    this.callOnWheelHalt = null
  }
  mounted() {
    setTimeout(() => {
      this.attachHandlers()
      this.checkSlides()
    }, 500)
  }
  checkSlides() {
    this.paddedLeftContainer = this.scrollWindow.value.closest('.page-gutter-left')
    this.slides.forEach((slide, i) => {
      const heightEl = slide.el.querySelector('.-uniform-width')
      slide.heightEl = heightEl
    })
    this.resizeHeight()
  }
  resizeHeight() {
    let wWidth = window.innerWidth
    const padLeft = parseInt(window.getComputedStyle(this.paddedLeftContainer).getPropertyValue('padding-left'))
    const visibleSliderWidth = wWidth - padLeft
    const slidesVisible = Math.ceil(visibleSliderWidth / this.slides[0].el.clientWidth)
    
    let height = null
    
    let i = this.currentI.value;
    while (i < this.slides.length) {
      if (i === this.currentI.value + slidesVisible) {
        break
      }
      const slide = this.slides[i]
      const thisHeight = slide.heightEl.offsetHeight
      if (height === null || height < thisHeight) {
        height = thisHeight
      }
      i++
    }
    this.scrollWindow.value.style.height = height + Math.min(wWidth / 12, 55) + 'px'
  }
  attachHandlers() {
    let timeout = null
    this.scrollWindow.value.addEventListener('wheel', e => {
      if (timeout === null) {
        this.wheelIsMoving = true
      } else {
        clearTimeout(timeout)
      }
      timeout = setTimeout(() => {
        timeout = null
        this.wheelIsMoving = false
        if (this.callOnWheelHalt !== null) {
          this.callOnWheelHalt()
          this.callOnWheelHalt = null
        }
      }, 200)
    })
  }
  next() {
    if (this.isScrollAuto || this.isScrollManual) {
      if (this.currentAnimationRun !== null) {
        this.currentAnimationRun.interrupt()
      }
    }
    if (this.currentI.value < this.slides.length - 1) {
      this.currentI.value++
    }
    if (this.currentAnimationRun !== null) {
      this.currentAnimationRun = null
      this.moveTo(this.slides[this.currentI.value].el)
    }
  }
  prev() {
    if (this.isScrollAuto || this.isScrollManual) {
      if (this.currentAnimationRun !== null) {
        this.currentAnimationRun.interrupt()
      }
    }
    if (this.currentI.value > 0) {
      this.currentI.value--
    }
    if (this.currentAnimationRun !== null) {
      this.currentAnimationRun = null
      this.moveTo(this.slides[this.currentI.value].el)
    }
  }
  moveTo(slideEl) {
    this.isScrollAuto = true
    const finalPos = slideEl.offsetLeft - this.scrollWindow.value.offsetLeft
    const start = this.scrollWindow.value.scrollLeft
    setTimeout(() => {
      this.transitionEndCallback = () => {
        clearTimeout(this.snapTimeout)
        this.snapTimeout = setTimeout(() => {
          this.isScrollAuto = false
          clearTimeout(this.snapTimeout)
          this.currentAnimationRun = null
        }, 50)
        this.transitionEndCallback = null
      }
      this.currentAnimationRun = new AnimationRun(
        1,
        finalPos,
        finalPos - start,
        this
      )
      let heightEl = this.slides[this.currentI.value].el.heightEl
      if (typeof heightEl === 'undefined') {
        heightEl = this.slides[this.currentI.value].el.querySelector('.-uniform-width')
        this.slides[this.currentI.value].el.heightEl = heightEl
      }
      this.resizeHeight()
      this.currentAnimationRun.start()
    }, 10)
  }
  addScrollBar(trackEl, handleEl) {
    this.scrollBarTrackEl = trackEl
    this.scrollBarHandleEl = handleEl
  }
  padLast() {
    const pad = this.scrollWindow.value.offsetWidth - this.lastUniformWidthEl.offsetWidth
    this.slides[this.slides.length-1].el.style.paddingRight = `${pad}px`
  }
  handleManualScroll() {
    if (this.isScrollAuto || this.isScrollManual) {
      return
    }
    clearTimeout(this.timeoutHandleManual)
    this.isScrollManual = true
    this.timeoutHandleManual = setTimeout(() => {
      if (this.mouseIsDown) {
        this.callOnMouseUp = () => {
          this.scrollToClosest()
        }
      } else if (this.touchIsDown) {
        this.callOnTouchUp = () => {
          this.scrollToClosest()
        }
      } else if (this.wheelIsMoving) {
        this.callOnWheelHalt = () => {
          this.scrollToClosest()
        }
      } else {
        this.scrollToClosest()
      }
      clearTimeout(this.timeoutHandleManual)
    }, 1000)
  }
  scrollToClosest() {
    let i = Math.round(this.scrollWindow.value.scrollLeft / this.slides[0].el.offsetWidth)
    if (i >= this.slides.length) {
      i = this.slides.length - 1
    } else if (i < 0) {
      i = 0
    }
    if (i !== this.currentI.value) {
      this.currentI.value = i
    } else {
      setTimeout(() => {
        this.moveTo(this.slides[this.currentI.value].el)
      }, 200)
    }
    this.isScrollManual = false
  }
  handlePageResize() {
    this.padLast(this.lastSlide)
  }
  registerSlideEl(i, slideRef) {
    this.slides[i].el = slideRef.value
    if (i === this.slides.length - 1) {
      this.lastUniformWidthEl = slideRef.value.querySelector('.-uniform-width')
      window.addEventListener('resize', () => {
        clearTimeout(this.resizeTimeout)
        this.resizeTimeout = setTimeout(() => {
          this.handlePageResize()
        }, 50)
      })
      this.handlePageResize()
    }
  }
}

const MAX_FRAMES_PER_SECOND = 200
class AnimationRun {
  constructor(seconds, finalPos, distance, master) {
    this.seconds = seconds
    this.steps = seconds * MAX_FRAMES_PER_SECOND
    this.finalPos = finalPos
    this.distance = distance
    this.scrollWindowEl = master.scrollWindow.value
    this.transitionEndCallback = master.transitionEndCallback
    this.master = master
    this.i = 0
    this.intervalHandle = null
    this.frameRequestHandle = null
    if (this.master.scrollBarTrackEl !== null) {
      if (this.master.scrollBarTrackWidth === null) {
        this.master.scrollBarTrackWidth = this.master.scrollBarTrackEl.value.offsetWidth
      }
      this.scrollBarFinalPos = this.master.currentI.value * this.master.scrollBarTrackWidth / this.master.slides.length
      this.scrollBarDistance = this.scrollBarFinalPos - this.master.scrollbarPos.value
    }
  }
  start() {
    this.intervalHandle = setInterval(() => {
      this.i++
    }, 1000 / MAX_FRAMES_PER_SECOND)
    this.frame()
  }
  frame() {
    if (this.i >= this.steps) {
      clearInterval(this.intervalHandle)
      this.scrollWindowEl.scroll({
        left: this.finalPos,
        behavior: 'auto',
      })
      if (this.transitionEndCallback !== null) {
        this.transitionEndCallback()
      }
      return
    }
    this.scrollWindowEl.scroll({
      left: this.finalPos - Math.pow(this.i / this.steps - 1, 4) * this.distance,
      behavior: 'auto',
    })
    
    if (this.master.scrollBarTrackEl !== null) {
      this.master.scrollbarPos.value = this.scrollBarFinalPos - Math.pow(this.i / this.steps - 1, 4) * this.scrollBarDistance
    }
    
    this.frameRequestHandle = requestAnimationFrame(() => this.frame())
  }
  interrupt() {
    clearInterval(this.intervalHandle)
    cancelAnimationFrame(this.frameRequestHandle)
  }
}
