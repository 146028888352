<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2.1177em"
    height="2.4em"
    viewBox="0 0 21.177 24"
  >
    <path
      d="M18.271,6.824h7.906V20.941H16.294l-.565-2.824H7.824V28H5V4H17.706l.565,2.824m-.565,11.294H20.53V15.294h2.824V12.471H20.53V9.647H17.706v2.824L16.294,9.647V6.824H13.471V9.647H10.647V6.824H7.824V9.647h2.824v2.824H7.824v2.824h2.824V12.471h2.824v2.824h2.824V12.471l1.412,2.824v2.824m-4.235-5.647V9.647h2.824v2.824H13.471m4.235,0H20.53v2.824H17.706Z"
      transform="translate(-5 -4)"
      fill="currentColor"
    />
  </svg>
</template>
