<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2.4em"
    height="2.4em"
    viewBox="0 0 24 24"
  >
    <circle
      cx="12"
      cy="12"
      r="12"
      fill="currentColor"
    />
  </svg>
</template>
