import { ref } from 'vue'

export default class {
  constructor() {
    this.abstractIsShowing = ref(null)
    this.titleSized = ref(null)
    this.abstractSized = ref(null)
    this.h = ref(0)
    this.w = ref(0)
    this.show1 = ref(false)
    this.show2 = ref(false)
    this.mountedCallback = null
    this.showTimeout1 = null
    this.showTimeout2 = null
  }
  _clearShowTimeouts() {
    clearTimeout(this.showTimeout1)
    clearTimeout(this.showTimeout2)
  }
  maximise() {
    this.abstractIsShowing.value = true
    this.h.value = this.abstractSized.value.offsetHeight
    this.w.value = Math.max(this.abstractSized.value.offsetWidth, this.titleSized.value.offsetWidth)
    this._clearShowTimeouts()
    this.showTimeout1 = setTimeout(() => {
      this.show1.value = true
    }, 280)
    this.showTimeout2 = setTimeout(() => {
      this.show2.value = true
    }, 350)
  }
  minimise() {
    this.abstractIsShowing.value = false
    this.show1.value = false
    this.show2.value = false
    this._clearShowTimeouts()
    // setTimeout(() => {
    this.h.value = this.titleSized.value.offsetHeight
    this.w.value = this.titleSized.value.offsetWidth
    // }, 100)
  }
}


