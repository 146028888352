<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.9188em"
    height="2.4em"
    viewBox="0 0 19.188 24"
  >
    <path
      d="M23.188,2H4V4.4l6.968,5.229a8.4,8.4,0,1,0,5.253,0L23.188,4.4V2M17.12,22.986l-3.526-2.063-3.526,2.063L11,18.993,7.9,16.307l4.089-.348,1.607-3.766L15.2,15.959l4.089.348-3.106,2.686Z"
      transform="translate(-4 -2)"
      fill="currentColor"
    />
  </svg>
</template>
