<template>
  <div
    ref="el"
    class="modal-confirmation items-start fixed inset-0 z-fixed bg-overlay flex justify-center pt-[8vh] phone-only:pt-[6vw]"
    style="display: none"
    :class="{ '-show': showConfirmationModal}"
  >
    <div
      ref="modalWindow"
      class="-window inline-block w-3/4 max-w-[752px] bg-white overflow-hidden phone-only:max-w-none phone-only:w-[88%] phone-only:max-h-full phone-only:h-[93vh]"
      @click.stop
    >
      <header
        ref="header"
        class="pt-[50px] px-[97px] tablet-down:px-[35px] relative pb-6 phone-only:pt-8"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
        >
          <path
            fill="#ac9467"
            d="M18 36a18 18 0 1 1 18-18 18.02 18.02 0 0 1-18 18Zm0-32.624a14.615 14.615 0 1 0 13.318 8.576L18.047 24.767l-.107-.109-7.708-7.978 2.428-2.345 5.459 5.672 11.4-11.014A14.547 14.547 0 0 0 18 3.376Z"
          />
        </svg>
        <div
          class="-close absolute top-9 right-9 cursor-pointer opacity-20 hover:opacity-100 phone-only:top-6 phone-only:right-6"
          @click="close"
        >
          <IconLgX class="text-10 phone-only:text-8" />
        </div>
      </header>
      <article
        v-if="confirmationMessage"
        v-html="confirmationMessage"
      >
      </article>
      <article v-else>
        <h3 class="text-30 mb-2 tablet-down:text-24">
          Thanks for your enquiry
        </h3>
        <p>A member of the team will get back to you shortly.</p>
        <p>In the meantime don't forget to follow us on</p>
      </article>
      <footer>
        <social-links
          :fb="socialLinks.fb"
          :tw="socialLinks.tw"
          :linked-in="socialLinks.linkedIn"
        ></social-links>
      </footer>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import IconLgX from '~/svg/IconLgX.vue';
import useModalConfirmation from '../../composables/useModalConfirmation';
import SocialLinks from '~/widgets/SocialLinks.vue';

const { showConfirmationModal, confirmationMessage } = useModalConfirmation();

const props = defineProps({
  socialLinks: {
    type: Object,
    required: true,
  },
})

const el = ref(null)

const close = () => {
  showConfirmationModal.value = false
}

onMounted(() => {
  setTimeout(() => {
    el.value.style.display = 'flex'
  }, 500)
});

</script>
