import { ref } from 'vue'

const showConfirmationModal = ref(false),
  confirmationMessage = ref(null)

export default function useModalConfirmation() {
  return {
    showConfirmationModal,
    confirmationMessage,
  }
}
