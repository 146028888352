
const options = [
  {
    id: '.wills.',
    label: 'Wills and Powers of Attorney',
  },
  {
    id: '.probate.',
    label: 'Probate and Deceased Estates',
  },
  {
    id: '.estates.',
    label: 'Estate Disputes',
  },
  {
    id: '.',
    label: 'Family Law',
  },
]
const url = (id) => {
  return `https://robbinswatson${id}settify.com.au/landing?referral=modal`
}

let _beginOnlineNodes = null

const beginOnlineNodes = () => {
  if (_beginOnlineNodes === null) {
    _beginOnlineNodes = options.map(link => {
      return {
        url: url(link.id),
        title: link.label,
        children: [],
        type: null,
        contentId: 'begin-online',
      }
    })
  }
  return _beginOnlineNodes
}




export default function() {
  return {
    options,
    url,
    beginOnlineNodes,
  }
}