<template>
  <a
    class="cursor-pointer"
    @click="tab = tabNum"
  ><slot></slot></a>
</template>
<script setup>
import useModalEnquiry from '../../composables/useModalEnquiry.js'

defineProps({
  tabNum: {
    type: Number,
    required: true,
  },
})

const { tab } = useModalEnquiry()
</script>
