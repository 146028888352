<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="0.4em"
    height="0.8em"
    viewBox="-5 1 8 10"
  >
    <path
      d="M8.59,14.817,12.407,11,8.59,7.175,9.765,6l5,5-5,5Z"
      transform="matrix(-1,0,0,1,10.5698,-3.52444)"
      fill="currentColor"
    />
  </svg>
</template>
