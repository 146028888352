<template>
  <div
    class="menu-hamburger"
  >
    <div>
      <div class="-top" />
      <div class="-mid" />
      <div class="-bottom" />
    </div>
  </div>
</template>
