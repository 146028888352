<template>
  <div
    ref="slideEl"
    class="-slide"
  >
    <component
      :is="slide.href ? 'a' : 'span'"
      class="-uniform-width"
      :href="slide.href"
    >
      <header v-if="slide.img">
        <div class="-img picture-inset">
          <ResPicture v-bind="slide.img" />
        </div>
      </header>
      <footer class="pt-4">
        <h3 class="h5 mb-[3px]">
          {{ slide.title }}
        </h3>
        <p class="sml-caps">
          {{ slide.subTitle }}
        </p>
      </footer>
    </component>
  </div>
</template>
<script setup>
import { ref, watch, onMounted } from 'vue'
import ResPicture from './widgets/ResPicture.vue'

const props = defineProps({
  slide: {
    type: Object,
    required: true,
  },
  state: {
    type: Object,
    required: true,
  },
  i: {
    type: Number,
    required: true,
  },
})


const isFocused = ref(null)

const slideEl = ref(null)

const checkIfShouldShow = (i) => {
  if (i === props.i) {
    if (isFocused.value !== true) {
      if (isFocused.value === false) {
        props.state.moveTo(slideEl.value)
      }
      isFocused.value = true
    }
  } else if (isFocused.value !== false) {
    isFocused.value = false
  }
}

watch(props.state.currentI, (newI) => {
  checkIfShouldShow(newI)
})




onMounted(() => {
  checkIfShouldShow(props.state.currentI.value)
  props.state.registerSlideEl(props.i, slideEl)
})

</script>
